const showToastMessage = require('../action_helpers/showToastMessage');
const fb = require('./firebaseUtils');
const strings = require('../../config/strings');
const z2hApp = require('./z2hApp');
const parseUrl = require('../action_helpers/parseUrl');

const processLink = (data) => {
  if (data) {
 
    const { pathname } = parseUrl(data.deepLink);

    if (pathname.indexOf('/licence/csl/') >= 0 || pathname.indexOf('/licence/1010/') >= 0) {
      z2hApp
        .applyPartnerLicenceKey({ deepLink: pathname })
        .then((res) => {
          fb.analyticsLogEvent('Licence handler', { partner: res.data.partner });
          if (res.status === 200) {
            localStorage.setItem('pendingEvent', 'partnerPremium');
            localStorage.setItem('pendingEventData', res.data.partner);
          } else {
            if (res.data.error == '1711') {
              showToastMessage(strings.LICENCE_KEY_USED()());

              fb.analyticsLogEvent('Licence handler - already used', { partner: res.data.partner, link: pathname });
            } else if (res.error_code == '1713') {
              showToastMessage(strings.LICENCE_KEY_ALREADY_APPLIED()());

              fb.analyticsLogEvent('Licence handler - already used', { partner: res.data.partner, link: pathname });
            } else {
              showToastMessage(strings.LICENCE_KEY_INVALID()());

              fb.analyticsLogEvent('Licence handler - invalid', { partner: res.data.partner, link: pathname });
            }
          }
        })
        .catch((e) => {
          fb.analyticsLogEvent('Licence handler - error', { partner: res.data.partner, link: pathname });
          showToastMessage(strings.LICENCE_KEY_INVALID()());
        });
    }
  }
};
const setupDynamicLinks =  () => {
  if (process.env.CORDOVA) {
    window.cordova.plugins.firebase.dynamiclinks.getDynamicLink().then(function (data) {
      if (data) {
        if (window.state?.launchData?.uid) {
          processLink(data);
        } else {
          localStorage.setItem('pp', '1');
          localStorage.setItem('pp2', JSON.stringify(data));
        }
      }
    });

    window.cordova.plugins.firebase.dynamiclinks.onDynamicLink(function (data) {
      if (data) {
        if (window.state?.launchData?.uid) {
          processLink(data);
        } else {
          localStorage.setItem('pp', '1');
          localStorage.setItem('pp2', JSON.stringify(data));
        }
      }
    });
  }

  return Promise.resolve();
};

const { setOnAuthStateChanged } = require('../app/firebaseUtils');

setOnAuthStateChanged((user) => {
  setupDynamicLinks();
  if (user) {
    if (localStorage.getItem('pp') === '1') {
      processLink(JSON.parse(localStorage.getItem('pp2')));
      localStorage.removeItem('pp');
      localStorage.removeItem('pp2');
    }
  }
});
