const lottie = require('lottie-web');

function initialise(event, { newPane }) {
  //Lottie web player - https://github.com/airbnb/lottie-web

  //Find any lottie animations on the page.
  const animation = newPane.find('[data-lottie-animation]');
  animation.length &&
    animation.each((i, elem) => {
      const $elem = $(elem);
      $elem.empty();
      const dataId = $elem.data('id');
      const data = window.state.animations[dataId];

      //Create an animation for each of our json files in window.state.animations.
      window.state.animations[dataId].instance = lottie.loadAnimation({
        container: elem,
        renderer: 'svg',
        loop: data.loop,
        autoplay: data.autoplay,
        animationData: data.path,
      });
      //we've been given a callback for when the animation
      //completes.
      data.onComplete && (window.state.animations[dataId].instance.onComplete = data.onComplete);
      data.speed && window.state.animations[dataId].instance.setSpeed(data.speed);
    });
}

$('body').on('z2hApp.pagedisplayed', initialise);
