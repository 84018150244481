module.exports = {
  suggestedAddButton: require('./suggestedAddButton'),
  suggestedUsernames: require('./suggestedUsernames'),
  popupMenu: require('./popupMenu'),
  navBar: require('./navBar'),
  carousel: require('./carousel'),
  animations: require('./animations'),
  recents: require('./recents'),
  appRating: require('./appRating'),
  badges: require('./badges'),
  banner: require('./banner'),
  inAppMessaging: require('./inAppMessaging'),
  universalLinks: require('./universalLinks'),
};
