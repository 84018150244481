const $ = require('jquery')

const navigation = require('./navigation')

const LOADING_BAR_ANIMATION_DURATION = 1000

class LoadingBar {

	constructor() {
		this.loadingCurrentSegment = 0;
		this.loadingSegments = 8;
		this.loadingSegmentPercent = 100 / this.loadingSegments;

		this.increment = this.increment.bind(this)
		$('.loading-bar>span').eq(0).attr("style", "width:0%");
	}

	increment(n) {
		this.loadingCurrentSegment += n;
		this.loadingCurrentSegmentPercent = this.loadingCurrentSegment * this.loadingSegmentPercent;
		
		if (this.loadingCurrentSegment >= this.loadingSegments) {
			this.complete = true
		}

		$('.loading-bar>span').attr("style", "width:" + (this.loadingCurrentSegmentPercent) + "%");

		if (this.complete) {
			setTimeout(_ => {
				navigation.transitionOutOfLoadingScreen();
			}, LOADING_BAR_ANIMATION_DURATION)
		};
	}
}

module.exports = new LoadingBar()