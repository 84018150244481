const { urls, strings } = require('../../config');

// =================================================================================================

// =================================================================================================
function initialise(url) {
  //OLD: app rate plugin stuff...
  //Register openUrl with app rate
  //https://www.npmjs.com/package/cordova-plugin-apprate
  // AppRate.preferences = {x
  //   displayAppName: 'Rate forghetti',
  //   usesUntilPrompt: 30,
  //   promptAgainForEachNewVersion: false,
  //   reviewType: {
  //     ios: 'InAppReview',
  //     android: 'InAppReview',
  //   },
  //   storeAppURL: {
  //     ios: urls.CORDOVA_IOS_APP_STORE,
  //     android: urls.CORDOVA_ANDROID_APP_STORE,
  //   },
  //   customLocale: {
  //     title: strings.APPRATE_TITLE,
  //     message: strings.APPRATE_MESSAGE,
  //     cancelButtonLabel: strings.APPRATE_CANCEL,
  //     laterButtonLabel: strings.APPRATE_LATER,
  //     rateButtonLabel: strings.APPRATE_RATE,
  //     yesButtonLabel: strings.APPRATE_YES,
  //     noButtonLabel: strings.APPRATE_NO,
  //     appRatePromptTitle: strings.APPRATE_PROMPT_TITLE,
  //     feedbackPromptTitle: strings.APPRATE_FEEDBACK,
  //   },
  //   callbacks: {
  //     handleNegativeFeedback: function() {
  //       //window.open('mailto:feedback@example.com','_system');
  //     },
  //     onRateDialogShow: function(callback) {
  //       //console.log(callback);
  //       //callback(1); // cause immediate click on 'Rate Now' button
  //     },
  //     onButtonClicked: function(buttonIndex) {
  //       console.log('onButtonClicked -> ' + buttonIndex);
  //       //we don't actually know if they rated the item on the store.
  //       //only if they clicked to go to the store.
  //       if (buttonIndex === 3) {
  //         localStorage.setItem('step_rated_on_store', true);
  //         z2hApp
  //           .requestData('post', '', 'users/@me', {
  //             step_rated_on_store: true,
  //           })
  //           .then(_ => {
  //             this.initUpdatesPage();
  //           });
  //       }
  //     },
  //   },
  //   openUrl: url => {
  //     window.open(url, '_blank', 'location=yes');
  //   },
  // };
}

function appRate(e, now = false) {
  //Only rate on apps...
  if (!window.LaunchReview) return;

  if (!window.LaunchReview.isRatingSupported() || now) {
    LaunchReview.launch();
    z2hApp
      .requestData('post', '', 'users/@me', {
        step_rated_on_store: true,
      })
      .then((_) => {});
    localStorage.setItem('step_rated_on_store', true);
  } else if (
    LaunchReview.isRatingSupported() &&
    window.state.launchData &&
    !window.state.launchData.home.step_rated_on_store &&
    !localStorage.getItem('step_rated_on_store')
  ) {
    LaunchReview.rating();
  }
}
function appRateNow(e) {
  if (!window.LaunchReview) return;
  z2hApp.requestData('post', '', 'users/@me/complete-task', {
    taskName: 'step_rated_on_store',
  });

  appRate(e, true);
}

document.addEventListener('deviceready', () => {
  initialise();
});

// Whenever a new page is displayed, check if it contains any components we need to initialise
$('body').on('z2hApp.rate', appRate);
$('body').on('z2hApp.ratenow', appRateNow);
