const parseUrl = require('../action_helpers/parseUrl');
const parse = require('try-json');
const showToastMessage = require('../action_helpers/showToastMessage');
const strings = require('../../config').strings;
const sanitize = require('sanitize-html');
const { parseDomain, ParseResultType } = require('parse-domain');

const deepLink = {
  // Application Constructor
  initialize: function () {
    this.bindEvents();
    this.processing = false;
  },

  // Bind Event Listeners
  bindEvents: function () {
    // if (window.cordova) {
    //   document.addEventListener(
    //     'deviceready',
    //     () => {
    //       this.onDeviceReady();
    //       this.listen(500);
    //     },
    //     false,
    //   );
    // } else {
    //   this.listen(400);
    // }
  },

  // deviceready Event Handler
  onDeviceReady: function () {
    //We will listen for any universal link updates and put them in a localStorage.
    //A timer will check for items in the local storage and process the events.
    //This can not just be called from here, but also
    if (!window.cordova) return;

    window.universalLinks.subscribe(null, async function (eventData) {
      if (eventData.path === '/addLogin') {
        localStorage.setItem('pendingEvent', 'login');
        localStorage.setItem('pendingEventData', eventData.params.websiteUrl ? eventData.params.websiteUrl : '');
      } else if (eventData.params.invite) {
        localStorage.setItem('pendingEvent', 'invite');
        localStorage.setItem('pendingEventData', eventData.params.invite);
      } else if (eventData.params.referral || eventData.params.refer) {
        localStorage.setItem('pendingEvent', 'referral');
        localStorage.setItem('pendingEventData', eventData.params.referral || eventData.params.refer);
      } else if (eventData.path === '/getPremium') {
        localStorage.setItem('pendingEvent', 'premium');
        localStorage.setItem('pendingEventData', '');
      } else if (eventData.path === '/verify') {
        localStorage.setItem('waitingForData', '1');
        const socket = await z2hApp.requestData('get', '', `partner-access/${verify}/service`);
        localStorage.removeItem('waitingForData');
        localStorage.setItem('pendingEventSearch', socket.data.name);
        localStorage.setItem('pendingEventData', socket.data);
        localStorage.setItem('pendingEvent', 'verifyRequest');
      }

      if (eventData.params.search) {
        localStorage.setItem('pendingEventSearch', eventData.params.search);
      }
    });
  },

  processEvent: () => {
    let dontDoSearch = false;
    deepLink.processing = true;
    const pendingEvent = localStorage.getItem('pendingEvent');
    let pendingEventData = localStorage.getItem('pendingEventData');
    const pendingEventSearch = localStorage.getItem('pendingEventSearch');
    if (pendingEventData) sanitize(pendingEventData);

    if (pendingEvent === 'verifyRequest' && pendingEventData) {
      //actions

      const data = JSON.parse(pendingEventData);
    

      localStorage.setItem('verifyRequest', pendingEventData);

      if (data.previous_service_id) {
      try {
        const actions = require('../actions');
        const { newLoginOrDoodle } = actions;
        dontDoSearch = newLoginOrDoodle({
          z2hApp,
          dataId: data.previous_service_id,
          groupId: data.previous_group_id,
        });
      } catch (e) {
        console.log(e);
      }}
      
    }

    //find login
    if (pendingEvent === 'extension') {
      if (!pendingEventData) return;
      const viewService_dataCache = parse(localStorage.getItem('viewServiceExtensionCache')) || {};
      if (
        viewService_dataCache &&
        viewService_dataCache.website === pendingEventData &&
        viewService_dataCache.exp > Date.now()
      ) {
        // Cache it for another 10 seconds
        viewService_dataCache.exp = Date.now() + 30000;
        localStorage.setItem('viewServiceExtensionCache', JSON.stringify(viewService_dataCache));
        window.state.currentService = viewService_dataCache;
        z2hApp.paneNavigationFromObject({
          templateId: 'viewService',
          pane: 2,
          dataNav: 1,
          animated: false,
          data: viewService_dataCache,
        });
        return;
      }
      let url = parseUrl(pendingEventData).hostname;

      const parseResult = parseDomain(url);
      if (parseResult.type === ParseResultType.Listed) {
        const { subDomains, domain, topLevelDomains } = parseResult;
        url = domain;
        const dodgyDomains = ['www', 'auth', 'auth0'];
        if (dodgyDomains.indexOf(url) >= 0) {
          url = '';
        }
        if (domain.toLowerCase() === 'google' && subDomains.length >= 1) {
          url = subDomains[0];
        }
      } else if (parseResult.type === ParseResultType.Ip) {
        url = url;
      } else if (parseResult.type === ParseResultType.Reserved) {
        url = parseResult.labels[0];
      } else {
        url = '';
      }
      console.log('SEARCH');
      setTimeout(() => {
        const searchField = $('#pane-1').find('.search-input');
        //console.log(searchField);
        searchField.val(url).trigger('keyup');
      }, 300);
    }
    //add login
    else if (pendingEvent === 'login') {
      const beginAddNewService = require('../actions/services/beginAddNewService');
      //Throw a toast message out to say that we're doing something
      pendingEventData
        ? showToastMessage(strings.UNIVERSAL_LINK_NEW_LOGIN()(pendingEventData))
        : showToastMessage(strings.UNIVERSAL_LINK_NEW_LOGIN_NO_URL());

      window.z2hApp.fetchServicePresetsList();
      //window.state.userData.activeOnboarding = false;
      const websiteUrl = pendingEventData ? pendingEventData : '';
      beginAddNewService({ websiteUrl });

      //Receive invite
    } else if (pendingEvent === 'invite') {
      const joinGroup = require('../actions/groups/joinGroup');

      joinGroup({ z2hApp: window.z2hApp, code: pendingEventData });

      //Referal code
    } else if (pendingEvent === 'referral') {
      const userData = (window.state && window.state.userData) || {};
      const currentReferralCode = userData.referral_used || '';
      if (currentReferralCode !== '' && currentReferralCode !== pendingEventData) {
        showToastMessage(strings.REFERRAL_CODE_UPDATE_NOT_ALLOWED(), { html: true, timeout: 6000, center: true });
      } else {
        setTimeout((_) => {
          $('#referral-code-edit-field').val(pendingEventData).focus();
        }, 400);
      }
      window.z2hApp.paneNavigation('referralCode', '2', 2);

      //Premium page
    } else if (pendingEvent === 'premium') {
      window.z2hApp.paneNavigation('viewYourSubscription', '2', 1);
      //window.z2hApp.paneNavigation('viewProfile', '1', 1);
    } else if (pendingEvent === 'reonboarding') {
      const reonboarding = require('../actions/miscellaneous/reonboarding');
      reonboarding({ z2hApp });
    } else if (pendingEvent === 'partnerPremium') {
      const partnerPremium = require('../pages/profile/partnerPremium');
      //console.log(pendingEventData);
      partnerPremium(pendingEventData);
    } else if (pendingEvent === 'partnerSubscription') {
      const subscribePartner = require('../pages/profile/partnerPremium');
      subscribePartner('CSL');
    }
    //Search term
    if (pendingEventSearch && !dontDoSearch) {
      setTimeout(() => {
        const searchField = $('#pane-1').find('.search-input');

        searchField.val(pendingEventSearch).keyup();
      }, 500);
    }

    localStorage.removeItem('pendingEvent');
    localStorage.removeItem('pendingEventData');
    localStorage.removeItem('pendingEventSearch');
    deepLink.processing = false;
  },

  listen: (timeout) => {
    setTimeout(() => {
      if (
        !deepLink.processing &&
        (localStorage.getItem('pendingEvent') || localStorage.getItem('pendingEventSearch')) &&
        !$('.overlay').is(':visible') &&
        !$('.lock-screen').is(':visible')
      ) {
        deepLink.processEvent();
      }
      deepLink.listen(500);
    }, timeout);
  },
};

//==================================================================================================
function findMatchingLogin(website) {
  const groupsPromise = window.state.fullUserGroupsPromise || z2hApp.reloadFullGroupsList();

  // Helper function
  const loginMatch = (service) => service.website && hostnameMatch(service.website, website);

  console.time('Login search completed');

  if (!website) return Promise.resolve(null);
  const trimmedData = website.length > 30 ? website.substr(0, 27) + '...' : website;
  showToastMessage(strings.EXTENSION_ATTEMPT()(trimmedData), { html: true });

  // First of all, send off a request to get all the user's services
  const getAllServicesAndFindOneThatMatches = groupsPromise.then(async (_) => {
    const groups = window.state.groupsList;

    // Ensure personal group is at top of list
    groups.sort((a, b) => (a.personal ? -1 : 1));

    // Create an aggregated list of all services from all groups
    const allServices = groups.reduce((services, group) => {
      const servicesWithGroupIds = group.services.map((s) => ({ ...s, groupId: group.id }));
      return services.concat(servicesWithGroupIds);
    }, []);

    // Cache this this of services
    localStorage.setItem('currentServices', JSON.stringify(allServices));

    const matchingServices = allServices.filter((s) => loginMatch(s)).sort((a, b) => loginMatch(b) - loginMatch(a));
    return matchingServices[0];
  });

  // While that is going on, we will check if we have a matching service already in our cache
  const allServicesCached = parse(localStorage.getItem('currentServices')) || [];
  const allMatchingServices = allServicesCached.filter(loginMatch).sort((a, b) => loginMatch(b) - loginMatch(a));

  // This will create an array of promises. Each one is a promise to get the service details from
  // the server API. We do this to check that the service still exists.
  const checkIfEachServiceExists = allMatchingServices.map((s) => z2hApp.requestData('get', '', 'services/' + s.id));

  // Now use Promise.all to get an array of all the responses, and (when we have all the responses)
  // find the first result which was successful.
  const findCachedServiceThatMatches = Promise.all(checkIfEachServiceExists).then((results) => {
    return (results.find((r) => r.status <= 299) || {}).data;
  });

  // Now perform a race between both promises. I did try using Promise.race for this, but with that
  // if one of the promises resolved with 'null' then that is considered a winner.
  return new Promise((resolve) => {
    let otherOneDone = false;
    const resolveIfServiceFound = (service) => {
      if (service) resolve(service);
      // If both promises have completed but neither found a matching service, then resolve with null
      else if (otherOneDone) resolve(null);
      else otherOneDone = true;
    };
    getAllServicesAndFindOneThatMatches.then(resolveIfServiceFound);
    findCachedServiceThatMatches.then(resolveIfServiceFound);
  }).then((service) => {
    window.state.selectedGroupId = (service && (service.groupId || service.group_id)) || null;
    console.timeEnd('Login search completed');
    console.log(service ? 'Found login.' : 'Could not find a matching login.');
    return service;
  });

  // const groupsPromise = window.state.fullUserGroupsPromise || z2hApp.reloadFullGroupsList();

  // return groupsPromise.then(_ => {
  //   // Create an aggregated list of all services from all groups
  //   const allServices = window.state.groupsList.reduce((services, group) => {
  //     const waitForServices = group.services ? Promise.resolve(group.services) :
  //       z2hApp.fetchServices(group.id);

  //     waitForServices.then(servs => {
  //       const servicesWithGroupIds =
  //         servs && servs.map(s => ({ ...s, groupId: group.id }));
  //       return services.concat(servicesWithGroupIds);
  //     })

  //   }, []);

  //   localStorage.setItem('currentServices', JSON.stringify(allServices));
  //   const matchingServices = allServices.filter(s => typeof s === "object" && s.website).filter(s => loginMatch(s)).sort((a, b) => loginMatch(b) - loginMatch(a));
  //   return matchingServices[0];
  // });
}

// First of all, send off a request to get all the user's services
// const getAllServicesAndFindOneThatMatches = groupsPromise.then(response => {
//   if (response.status <= 299) {
//     const groups = Object.values(response.data);

//     // Ensure personal group is at top of list
//     groups.sort((a, b) => (a.personal ? -1 : 1));

//     // Create an aggregated list of all services from all groups
//     const allServices = groups.reduce((services, group) => {
//       const servicesWithGroupIds = group.services_list.map(s => ({ ...s, groupId: group.id }));
//       return services.concat(servicesWithGroupIds);
//     }, []);

//     // Cache this this of services
//     localStorage.setItem('currentServices', JSON.stringify(allServices));

//     // Get groups list and cache it
//     const groupsList = Object.values(response.data);
//     groupsList.forEach(grp => delete grp.services_list);
//     localStorage.setItem('groupsList', JSON.stringify(groupsList));
//     window.state.groupsList = groupsList;

//     const matchingServices = allServices.filter(s => loginMatch(s)).sort((a, b) => loginMatch(b) - loginMatch(a));
//     return matchingServices[0];
//   }
// });

// While that is going on, we will check if we have a matching service already in our cache
// const allServicesCached = parse(localStorage.getItem('currentServices')) || [];
// const allMatchingServices = allServicesCached.filter(loginMatch).sort((a, b) => loginMatch(b) - loginMatch(a));

// // This will create an array of promises. Each one is a promise to get the service details from
// // the server API. We do this to check that the service still exists.
// const checkIfEachServiceExists = allMatchingServices.map(s => z2hApp.requestData('get', '', 'services/' + s.id));

// // Now use Promise.all to get an array of all the responses, and (when we have all the responses)
// // find the first result which was successful.
// const findCachedServiceThatMatches = Promise.all(checkIfEachServiceExists).then(results => {
//   return (results.find(r => r.status <= 299) || {}).data;
// });

// Now perform a race between both promises. I did try using Promise.race for this, but with that
// if one of the promises resolved with 'null' then that is considered a winner.
//   return new Promise(resolve => {
//     let otherOneDone = false;
//     const resolveIfServiceFound = service => {
//       if (service) resolve(service);
//       // If both promises have completed but neither found a matching service, then resolve with null
//       else if (otherOneDone) resolve(null);
//       else otherOneDone = true;
//     };
//     getAllServicesAndFindOneThatMatches.then(resolveIfServiceFound);
//     findCachedServiceThatMatches.then(resolveIfServiceFound);
//   }).then(service => {
//     window.state.selectedGroupId = (service && (service.groupId || service.group_id)) || null;
//     console.timeEnd('Login search completed');
//     console.log(service ? 'Found login.' : 'Could not find a matching login.');
//     return service;
//   });
// }

//==================================================================================================

// Returns a number - The number of parts in each hostname that match, starting from the end
// e.g.:
// hostnameMatch('twitter.com', 'twitter.com') = 2
// hostnameMatch('secure.twitter.com', 'twitter.com') = 2
// hostnameMatch('secure.twitter.com', 'http://secure.twitter.com') = 3
// hostnameMatch('http://secure.twitter.com', 'http://login.twitter.com') = 2
// hostnameMatch('twitter.com', 'twitter.co.uk') = 0 (because .uk doesn't match .com so it gives up)

function hostnameMatch(h1, h2) {
  const url1 = parseUrl(h1).hostname;
  const url2 = parseUrl(h2).hostname;

  // Firstly check that both hostnames match if they are cut down to the same length
  const minLength = Math.min(url1.length, url2.length);
  if (url1.substr(-minLength) !== url2.substr(-minLength)) {
    return 0;
  }
  const matchingChars = url1.substr(-minLength);
  return matchingChars.split('.').length;
}

// const loginMatch = service => hostnameMatch(service.website, website);

//deepLink.initialize();
const { setOnAuthStateChanged } = require('../app/firebaseUtils');
const z2hApp = require('../app/z2hApp');

window.deepLink = deepLink;

setOnAuthStateChanged((user, userChanged) => {
  if (user && userChanged) {
    deepLink.onDeviceReady();
    deepLink.listen(800);
  }
});
