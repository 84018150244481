import {   firestore } from './firebaseUtils';

import { doc, getDoc, getFirestore, onSnapshot } from 'firebase/firestore';

const showToastMessage = require('../action_helpers/showToastMessage');
const fb = require('./firebaseUtils');
const strings = require('../../config/strings');
const z2hApp = require('./z2hApp');

const realtime = async () => {
  let uid;

  console.log('REALTIME');
  await firestore();
  try {
    uid = fb.getUid();
    if (!uid) {
      return;
    }
  } catch (e) {
    return;
  }
  window.state.updates = window.state.updates ? window.state.updates : {};
  //allow for a minute different between server time and our time.
  window.state.updates.lastUpdateTimestamp = Date.now() - 60000;
  if (localStorage.getItem('lastUpdateTimestamp')) {
    window.state.updates.lastUpdateTimestamp = Number(localStorage.getItem('lastUpdateTimestamp'));
  }

  const processDocs = (docs) => {
    for (const d of docs) {
      //Don't process the result if we just processed something with this ID less than 1500 ago
      if (
        window.state.updates.lastProcessId &&
        window.state.updates.lastProcessId === d.id &&
        window.state.updates.lastUpdateTimestamp + 500 >= d.timestamp
      ) {
        //we've already seen an update for this guy.
        continue;
      }
      //Or if the timestamp is old.
      if (window.state.updates.lastUpdateTimestamp  && window.state.updates.lastUpdateTimestamp >= d.timestamp) continue;

      window.state.updates.lastProcessId = d.id;
      window.state.updates.lastUpdateTimestamp = d.timestamp;
      localStorage.setItem('lastUpdateTimestamp', window.state.updates.lastUpdateTimestamp);

      //Go and get a groups list to update
      if (d.type === 'joinGroup') {
        const groupId = d.id;

        z2hApp
          .reloadFullGroupsList()
          .then(() => {
            if (uid !== d.initiator) {
              const group = window.state.groupsList.find((g) => g.id === d.id);

              group.owner_id === uid && showToastMessage(strings.REALTIME_JOIN_SERVICE_FOR_GROUP()(group.name));
            }

            window.state.launchData.groups_list = window.state.groupsList;
            localStorage.setItem('launch', JSON.stringify(window.state.launchData));
            return z2hApp.fetchServices(groupId);
          })
          .then(() => {
            z2hApp.reloadUpdateSections();
            z2hApp.refreshGroupsInNav();
          });
      } else if (d.type === 'newGroup') {
        const groupId = d.id;
        //const serviceId = d.id;

        z2hApp
          .reloadFullGroupsList()
          .then(() => {
            window.state.launchData.groups_list = window.state.groupsList;
            localStorage.setItem('launch', JSON.stringify(window.state.launchData));
            return z2hApp.fetchServices(groupId);
          })
          .then(() => {
            z2hApp.reloadUpdateSections();
            z2hApp.refreshGroupsInNav();
          });
      } else if (d.type === 'editGroup') {
        const groupId = d.id;
        const group = window.state.groupsList.find((g) => g.id === d.id);

        //const serviceId = d.id;
        if (window.state.fullUserGroupsLastReload <= Date.now() - 1000 || d.initiator !== uid) {
          if (d.data && d.data.action === 'ban' && d.data.user === uid) {
            showToastMessage(strings.REALTIME_BAN_SERVICE_FOR_GROUP()(group.name));
          } else if (d.data && d.data.action === 'kick' && d.data.user === uid) {
            showToastMessage(strings.REALTIME_KICK_SERVICE_FOR_GROUP()(group.name));
          }
          z2hApp
            .reloadFullGroupsList()
            .then(() => {
              window.state.launchData.groups_list = window.state.groupsList;
              localStorage.setItem('launch', JSON.stringify(window.state.launchData));
              return z2hApp.fetchAndShowServices(groupId, false, true);
            })
            .then(() => {
              z2hApp.reloadUpdateSections();
              z2hApp.refreshGroupsInNav();
              //
            });
        }
      } else if (d.type === 'removeGroup') {
        const groupId = d.id;
        //const serviceId = d.id;

        if (window.state.fullUserGroupsLastReload <= Date.now() - 1000 || uid !== d.initiator) {
          const group = window.state.groupsList.find((g) => g.id === d.groupId);
          if (uid !== d.initiator) {
            showToastMessage(strings.REALTIME_REMOVE_GROUP()(group.name));
          }

          z2hApp.reloadFullGroupsList().then(() => {
            window.state.launchData.groups_list = window.state.groupsList;
            localStorage.setItem('launch', JSON.stringify(window.state.launchData));
            z2hApp.reloadUpdateSections();
            z2hApp.refreshGroupsInNav();
            return;
          });
        }
      }
       else if (d.type === 'newService') {
        const groupId = d.groupId;
        const serviceId = d.id;
        z2hApp.fetchAndShowServices(groupId);
        if (uid !== d.initiator) {
          const group = window.state.groupsList.find((g) => g.id === d.groupId);
          showToastMessage(strings.REALTIME_NEW_SERVICE_FOR_GROUP()(group.name));
        }
        z2hApp.reloadUpdateSections();
      } else if (d.type === 'deleteService') {
        const groupId = d.groupId;
        const serviceId = d.id;
        z2hApp.fetchAndShowServices(groupId);
        if (uid !== d.initiator) {
          const group = window.state.groupsList.find((g) => g.id === d.groupId);
          showToastMessage(strings.REALTIME_REMOVE_SERVICE_FOR_GROUP()(group.name));
        }

        z2hApp.reloadUpdateSections();
      } else if (d.type === 'updateService') {
        const groupId = d.groupId;
        const serviceId = d.id;
        z2hApp.fetchAndShowServices(groupId);

        z2hApp.reloadUpdateSections();
      }

      //At the moment the only action we're interested in
      //is if they changed the language.
      else if (d.type == 'updateUser') {
        if (d.data && d.data.region && localStorage.getItem('language') !== d.data.region) {
          localStorage.setItem('language', d.data.region);
          require('../../config/strings').setLocale(d.data.region || 'gb');
          setTimeout(() => window.location.reload(), 500);
        } else if (
          d.data &&
          d.data.theme_settings &&
          localStorage.getItem('theme_settings') !== JSON.stringify(d.data.theme_settings)
        ) {
          localStorage.setItem('theme_settings', JSON.stringify(d.data.theme_settings));
          z2hApp.setTheme(
            z2hApp.getThemeFromIntValue(d.data.theme_settings.mode),
            z2hApp.getThemeColourFromIntValue(d.data.theme_settings.colour),
          );
        }
      }
    }
  };
  let oldSnapshot = null;
  if (window.cordova) {

    FirebasePlugin.listenToDocumentInFirestoreCollection(
      (event) => {
        switch (event.eventType) {
          case 'id':
            const listenerId = event.id;
            console.log('Successfully added document listener with id=' + listenerId);
            window.realtimeListenerId = listenerId;
            break;
          case 'change':
            console.log('Detected document change');
            console.log('Source of change: ' + event.source);
            console.log('Read from local cache: ' + event.fromCache);
            if (!event.fromCache && event.snapshot) {


              if (oldSnapshot === JSON.stringify(event.snapshot)) {
                return;
              }
              oldSnapshot = JSON.stringify(event.snapshot);

              console.log('Document snapshot: ' + JSON.stringify(event.snapshot));
              let array = [];
              if (typeof event.snapshot === 'object') {
                array.push(event.snapshot);
              } else {
                array = event.snapshot;
              }
              processDocs(array);
            }
            break;
        }
      },
      (error) => {
        console.error('Error listening to document in firestore collection', error);
      },
      uid,
      'actions',
      true,
    );
  } else {
    const docRef = doc(getFirestore(), `actions/${uid}`);
    const docSnap = onSnapshot(docRef, (docSnap) => {
      console.log('SNAP', docSnap);
      const source = docSnap.metadata.hasPendingWrites ? 'Local' : 'Server';
      const data = docSnap.data();
      if (!data) return;

      if (oldSnapshot === JSON.stringify(data)) {
        return;
      }
      oldSnapshot = JSON.stringify(data);

      let array = [];
      if (typeof data === 'object') {
        array.push(data);
      } else {
        array = data.data();
      }
      processDocs(array);
    });
  }
};

let lastUser = null;
const { setOnAuthStateChanged } = require('../app/firebaseUtils');
setOnAuthStateChanged((user) => {
  if (user && lastUser !== user.uid) {
    realtime();
    lastUser = user.uid;
  } else {
    lastUser = null;
    if (window.realtimeListenerId && window.cordova) {
      FirebasePlugin.removeFirestoreListener(
        (event) => {
          console.log('Stopped listening to document in firestore collection', event);
        },
        (error) => {
          console.error('Error stopping listening to document in firestore collection', error);
        },
        window.realtimeListenerId,
      );
    }
  }
});
