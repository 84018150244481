const { strings } = require('../../config');

function initialise(event, { newPane }) {
  const suggAddButton = newPane.find('.suggested-add-button');

  if (suggAddButton.length) {
    suggAddButton.each((i, elem) => {
      // Get a reference to the input field we have been associated with
      const searchInputId = $(elem).attr('data-searchinputid');
      const searchInput = $(`#${searchInputId}`);

      // On every keypup/change within the input field, change what we display
      searchInput.on('keyup change', (e) => {
        const input = $(e.currentTarget);
        const val = input.val().trim();
        if (val) {
          suggAddButton.show();
          suggAddButton.find('.primary-text-content').text(strings.GROUPS_NEW_RIGHT_NAV() + ' "' + val + '"');
          suggAddButton.find('.primary-text-content').data('value', val);
        } else {
          // Hide our component if the input field does not have a value
          suggAddButton.hide();
        }
      });
    });
  }
}

// Whenever a new page is displayed, check if it contains any components we need to initialise
$('body').on('z2hApp.pagedisplayed', initialise);
