

$('.popup-menu').on('click', function () {
  $(this).fadeOut();
  $('#nav-bar-add').removeClass('close')
})

$('#nav-bar-add').on('click', function () {
  $('.popup-menu').fadeIn();
  $('#nav-bar-add').addClass('close')

})