const showAlertMessage = require('../../action_helpers/showAlertMessage');
const config = require('../../../config');
const z2hApp = require('../../app/z2hApp');
const strings = config.strings;
const urls = config.urls;

module.exports = async (partner) => {
  //let partnerUrl = partner;
  //partnerUrl = partnerUrl.replace('{{LANGUAGE}}', localStorage.getItem('language')).replace('"', '');
  //const response = await z2hApp.requestData('get', '', `partner/text?url=${partnerUrl}`);
  const response = await z2hApp.requestData(
    'get',
    '',
    `partner/text/?partner=${partner}&action=apply&language=${localStorage.getItem('language')}`,
  );
  let message = '';
  for (const text of response.data.body) {
    message += text + '<br/>';
  }
  message += `
    <br/>
      <button class="column text-button"  onClick="window.location.reload()">
      ${response.data.button}</button>`;

  showAlertMessage(message, true, response.data.header);

  window.state.launchData.home.premium = true;
  localStorage.setItem('launch', JSON.stringify(window.state.launchData));

  //The disalog box
  $('body').on('z2hApp.pagedisplayed', () => {
    $('.dialogMgr_root').click();
  });
};
