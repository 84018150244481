require('slick-carousel');

//uses the slick plugin from ken wheeler
//https://github.com/kenwheeler/slick
function initialise(event, { newPane }) {
  const carousel = newPane.find('[data-slick]');
  carousel.length &&
    carousel.each((i, elem) => {
      //Firstly we need to do some data building
      //z2hApp doesn't really allow us to build the kind
      //of template structures we would like so we are going
      //to do it here.
      //Your page should set a window.state.carousel['someID'] tmeplate
      //definition.... We're going to build it here and insert it before
      //we do any carousel stuffx

      const $elem = $(elem);

      //do we already have a slick.
      const alreadySlicked = $elem.hasClass('slick-initialized');
      if (alreadySlicked) return;

      const dataId = $elem.data('id');
      const templates = window.state.carousel[dataId];

      buildHTML(templates, $elem);
      //we can pass in options with the attribute data-slick
      //so no need to do that here...
      $elem.slick({});

      //Throttled refresh of the carousel layout
      //Avoids the text and images overflowing with screen rotation
      var doThisAfterResizing;
      $(window).on('resize orientationchange', function () {
        clearTimeout(doThisAfterResizing);
        doThisAfterResizing = setTimeout(function () {
          //Resetting dimensions does not appear to snap to the current slide correctly?
          $elem.slick('setDimensions');
          //Force slider to jump to current/correct slide
          var currentSlide = $elem.slick('slickCurrentSlide');
          $elem.slick('slickGoTo', currentSlide, false);
        }, 200);
      });
    });
}

function buildHTML(templates, elem) {
  for (const t of templates) {
    let page = $('<div></div>');
    for (const r of t.rows) {
      page.append(z2hApp.constructRow(r));
    }

    elem.append(page);
  }
}
$('body').on('z2hApp.pagedisplayed', initialise);
