import { firestore } from './firebaseUtils';

import { doc, getDoc, getFirestore, onSnapshot } from 'firebase/firestore';

const showToastMessage = require('../action_helpers/showToastMessage');
const fb = require('./firebaseUtils');
const strings = require('../../config/strings');
const z2hApp = require('./z2hApp');
const packageJson = require('../../package.json');

const getCurrentVersion = () => {
  let version;
  if (window.cordova) {
    version = `${packageJson.mobile_version}`;
  } else if (window.state.electron) {
    version = `${packageJson.electron_version}`;
  } else if (window.state.extension) {
    version = packageJson.extension_version;
  } else {
    version = packageJson.version;
  }
  return version;
};
const devfunctions = () => {
  return {
    showBiometrics: async () => {
      if (!window.cordova) return Promise.resolve(false);
      return Promise.resolve(window.ss && localStorage.getItem('bio'));
    },

    isLatestVersion: async () => {
      const version = getCurrentVersion();
      const res = await z2hApp.requestData('get', '', 'settings/version');

      if (window.cordova) {
        if ((res.data && res.data.mobile)) {
          if (res.data.mobile !== version) {
            return true;
          }
        }
      } else if (window.state.electron) {
        if (res.data && res.data.desktop) {
          if (res.data.desktop !== version) {
            return true;
          }
        }
      } else if (window.state.extension) {
        if (res.data && res.data.extension) {
          if (res.data.extension !== version) {
            return true;
          }
        }
      } else {
        if (res.data && res.data.web) {
          if (res.data.web !== version) {
            return true;
          }
        }
      }
      return false;
    },
  };
};

const notes = async () => {
  let uid;
  console.log('NOTES');
  await firestore();
  try {
    uid = fb.getUid();
    if (!uid) {
      return;
    }
  } catch (e) {
    return;
  }
  const processNotes = async (docs) => {

    let allRead=true;
    window.state.notifications = window.state.notifications ? window.state.notifications : [];

    const compareOld = JSON.stringify(window.state.notifications.map((n) => { return { id: n.id, read: n.read, archive: n.archive || '', priority: n.priority, sequence_number: n.sequence_number }} ));
    let markAsUnread = false;
    let immediateNotify = false;
    window.state.notifications = [];
    for (const d of docs) {
      //order by sequence number
      const entries = Object.values(d);

      entries.sort((a, b) => {
        return a?.sequence_number - b?.sequence_number;
      });

      for (const notification of entries) {
        const existing = window.state.notifications.find((note) => note.id === notification.id);
        //if this is an existing notification

        //we can have multiple notifications with the same id, the first won is the winner!
        if (existing) {
          continue;
        } else {
          window.state.notifications.push(notification);
        }

        //Remove the note in some cases
        if (notification?.archive) {
          window.state.notifications = window.state.notifications.filter((note) => note.id !== notification.id);
          continue;
        }

        if (notification.devices && notification.devices.length > 0) {
          if (!notification.devices.includes(window.cordova ? 'mobile' : 'web')) {
            window.state.notifications = window.state.notifications.filter((note) => note.id !== notification.id);
            continue;
          }
        }

        if (window.cordova && notification.platforms && notification.platforms.length > 0) {
          if (!notification.platforms.includes(window.device.platformId)) {
            window.state.notifications = window.state.notifications.filter((note) => note.id !== notification.id);
            continue;
          }
        }

        if (notification.locales && !notification.locales.length > 0) {
          if (!notification.locales.includes(localStorage.getItem('language'))) {
            window.state.notifications = window.state.notifications.filter((note) => note.id !== notification.id);
            continue;
          }
        }

        if (notification?.device_function && notification?.device_function !== '') {
          const func = devfunctions();

          if (func[notification?.device_function]) {
            const funcToCall = func[notification.device_function];
            const result = await funcToCall();

            if (!result) {
              window.state.notifications = window.state.notifications.filter((note) => note.id !== notification.id);
              continue;
            }
          }
        }

        if (!notification.read && notification.must_be_read) {
          markAsUnread = true;
        }
        if (!notification.read) {
          allRead = false;
        }

        if (!immediateNotify && notification.popup && !notification.read) {
          immediateNotify = true;
          const payload = notification?.inappmessage || {};
          localStorage.setItem('inappmessage', JSON.stringify(payload));

          //we're going to show the inapp message
          //so we should mark it as read

          if (notification.popup_means_read) {
            z2hApp.requestData('post', '', `notifications/${notification.id}/mark-as-read`, { id: notification.id });
          }
        }
      }
    }

    if (markAsUnread && localStorage.getItem('notifications_read')) {
      localStorage.removeItem('notifications_read');
    }
    if (window.state.notifications.length === 0 || allRead) {
      localStorage.setItem('notifications_read', Date.now());
    }

    const compareNew = JSON.stringify(window.state.notifications.map((n) => { return { id: n.id, read: n.read, archive: n.archive || '', priority: n.priority, sequence_number: n.sequence_number }} ));

    if (compareOld !== compareNew) {
      const landingPage = $('#pane-2').find('#notifications');
      if (landingPage.hasClass('active')) {
        z2hApp.paneNavigation('notificationsFromBell', $('#pane-2'), 0, null, null, null, null, null, false);
        landingPage.removeClass('active');
      }
      z2hApp.setSectionPaneActive(1);
    }
  };
  if (window.cordova) {
    let oldSnapshot = null;
    FirebasePlugin.listenToDocumentInFirestoreCollection(
      (event) => {
        switch (event.eventType) {
          case 'id':
            const listenerId = event.id;
            console.log('Successfully added document listener with id=' + listenerId);
            window.notesListenerId = listenerId;
            break;
          case 'change':
            console.log('Detected document change');
            console.log('Source of change: ' + event.source);
            console.log('Read from local cache: ' + event.fromCache);

            if (!event.fromCache && event.snapshot) {

              if (oldSnapshot === JSON.stringify(event.snapshot)) {
                return;
              }
              oldSnapshot = JSON.stringify(event.snapshot);

              console.log('Document snapshot: ' + JSON.stringify(event.snapshot));
              let array = [];
              if (typeof event.snapshot === 'object') {
                array.push(event.snapshot);
              } else {
                array = event.snapshot;
              }
              processNotes(array);
            }
            break;
        }
      },
      (error) => {
        console.error('Error listening to document in firestore collection', error);
      },
      uid,
      'notifications',
      true,
    );
  } else {
    const docRef = doc(getFirestore(), `notifications/${uid}`);
    onSnapshot(docRef, (docSnap) => {
      const source = docSnap.metadata.hasPendingWrites ? 'Local' : 'Server';
      const data = docSnap.data();
      if (!data) return;
      let array = [];
      if (typeof data === 'object') {
        array.push(data);
      } else {
        array = data.data();
      }
      processNotes(array);
    });
  }
  return {};
};

let lastUser = null;

const { setOnAuthStateChanged } = require('../app/firebaseUtils');
setOnAuthStateChanged((user) => {
  if (user && lastUser !== user.uid) {
    notes();
    lastUser = user.uid
  } else {
    lastUser = null;
    if (window.notesListenerId && window.cordova) {
      FirebasePlugin.removeFirestoreListener(
        (event) => {
          console.log('Stopped listening to document in firestore collection', event);
        },
        (error) => {
          console.error('Error stopping listening to document in firestore collection', error);
        },
        window.notesListenerId,
      );
    }
  }
});
