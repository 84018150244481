const DialogMgr = require('dialog-manager');
const { FirebaseError } = require('firebase/app');

const allowedTemplates = [
  'loginList',
  'groupsList',
  'healthcheck_welcome',
  'viewProfile',
  'viewGroupDetails',
  'viewServiceDetails',
  'viewProfileAccount',
  'notifications',
];

let processing = false;

async function initialise() {
  if (!window.cordova) {
    window.state.inappMessages = true;
    return;
  }
  //const hasPermission = await FCM.hasPermission();

  //if (!hasPermission) {
  // await FCM.requestPushPermission({
  //   ios9Support: {
  //     timeout: 10, // How long it will wait for a decision from the user before returning `false`
  //     interval: 0.3, // How long between each permission verification
  //   },
  // });
  //}

  const hasPermission = new Promise((resolve, reject) => {
    FirebasePlugin.hasPermission((hasPermission) => {
      if (hasPermission) {
        console.log('Permission granted');
        resolve(hasPermission);
      } else {
        console.log('Permission denied');
        resolve(hasPermission);
      }
    });
  });

  const hp = await hasPermission;

  if (!hp) {
    const askPermission = new Promise((resolve, reject) => {
      FirebasePlugin.grantPermission((hasPermission) => {
        if (hasPermission) {
          console.log('Permission granted');
          resolve(hasPermission);
        } else {
          console.log('Permission denied');
          resolve(hasPermission);
        }
      });
    });

    await askPermission;
  }

  //console.log('fcm token init:' + token);
  const tokenPromise = new Promise((resolve, reject) => {
    FirebasePlugin.getToken(
      (token) => {
        resolve(token);
      },
      (error) => {
        reject(error);
      },
    );
  });

  let token;
  try {
    token = await tokenPromise;
  } catch (error) {
    console.log(error);
  }

  console.log('Got device token: ', token);

  //await FCM.subscribeToTopic('all');
  FirebasePlugin.subscribe(
    'all',
    (success) => {},
    (error) => {},
  );

  if (window.state.launchData.home.premium) {
    ///await FCM.subscribeToTopic('premium_user');
    //await FCM.unsubscribeFromTopic('free_user');
    FirebasePlugin.subscribe(
      'premium_user',
      (success) => {},
      (error) => {},
    );
    FirebasePlugin.unsubscribe(
      'free_user',
      (success) => {},
      (error) => {},
    );
  }

  if (!window.state.launchData.home.premium) {
    // await FCM.subscribeToTopic('free_user');
    // await FCM.subscribeToTopic('premium_user');
    FirebasePlugin.unsubscribe(
      'premium_user',
      (success) => {},
      (error) => {},
    );
    FirebasePlugin.subscribe(
      'free_user',
      (success) => {},
      (error) => {},
    );
  }

  //await FCM.subscribeToTopic('upgrading');
  //await FCM.subscribeToTopic('test');
  //await FCM.subscribeToTopic('healthcheck');
  FirebasePlugin.subscribe(
    'upgrading',
    (success) => {},
    (error) => {},
  );
  FirebasePlugin.unsubscribe(
    'test',
    (success) => {},
    (error) => {},
  );
  FirebasePlugin.unsubscribe(
    'healthcheck',
    (success) => {},
    (error) => {},
  );
  // const disposable = FCM.onTokenRefresh(token => {
  //   // ...
  //   //console.log('FCM token:' + token);
  // });
  // window.cordova.plugins.firebase.messaging.onTokenRefresh(function () {
  //   console.log('Device token updated');
  // });

  //FCM.eventTarget.addEventListener('notification', handleNotification, false);

  //if the user stepped on the notification button...
  // FCM.getInitialPushPayload().then(payload => {
  //   if (payload && payload.display_in_app) {
  //     const inapptodisplay = {
  //       title: payload.title,
  //       image_src: payload.image_src,
  //       body: payload.body,
  //       action: payload.action,
  //       data_id: payload.data_id,
  //       button: payload.button,
  //     };
  //     localStorage.setItem('inappmessage', JSON.stringify(inapptodisplay));
  //   }
  // });
  //   window.cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
  //     if (payload && payload.display_in_app) {
  //       const inapptodisplay = {
  //         title: payload.title,
  //         image_src: payload.image_src,
  //         body: payload.body,
  //         action: payload.action,
  //         data_id: payload.data_id,
  //         button: payload.button,
  //       };
  //       localStorage.setItem('inappmessage', JSON.stringify(inapptodisplay));
  //     }
  //     window.cordova.plugins.firebase.messaging.clearNotifications(function () {
  //       console.log('Notification messages cleared successfully');
  //     });
  //   });

  //   window.cordova.plugins.firebase.messaging.onMessage(function (payload) {
  //     if (payload && payload.display_in_app) {
  //       const inapptodisplay = {
  //         title: payload.title,
  //         image_src: payload.image_src,
  //         body: payload.body,
  //         action: payload.action,
  //         data_id: payload.data_id,
  //         button: payload.button,
  //       };
  //       localStorage.setItem('inappmessage', JSON.stringify(inapptodisplay));
  //     }
  //     window.cordova.plugins.firebase.messaging.clearNotifications(function () {
  //       console.log('Notification messages cleared successfully');
  //     });
  //   });

  //   window.state.inappMessages = true;
  // }

  window.state.inappMessages = true;
  FirebasePlugin.onMessageReceived(function (payload) {
    if (payload && payload.display_in_app) {
      localStorage.setItem('inappmessage', JSON.stringify(payload));
    }
    FirebasePlugin.clearAllNotifications(function () {
      console.log('Notification messages cleared successfully');
    });
  });
}

const heading = (heading) => ({
  template: 'block-text-group',
  fields: {
    primary_html: heading,
  },
});

const image = (src) => ({
  template: 'block-lochy',
  selector: '',
  attributes: [
    { selector: 'img', type: 'src', content: src },
    { selector: '', type: 'class', content: 'no-border' },
    { selector: 'img', type: 'class', content: 'small' },
  ],
});

const info = (info) => ({
  template: 'block-text-group',
  fields: {
    primary_html: info,
  },
});

const row = () => ({
  template: 'block-section-row',
  fields: {
    class: 'centered-text',
  },
  rows: [],
});

const ctaButton = (action, id, button) => ({
  template: 'block-text-button',
  fields: {
    action: action,
    data_id: id,
    text: button,
    class: 'cta-button',
  },
});

const buildButton = (action, id, button) => {
  const $row = z2hApp.constructBlock(window.z2hTemplates.compileBlockAttribute(row()));
  $row
    .find('.column-group')
    .append(z2hApp.constructBlock(window.z2hTemplates.compileBlockAttribute(ctaButton(action, id, button))));
  return $row;
};

function handleNotification(data) {
  const z2hTemplates = require('../app/templates');
  const { z2hApp } = window;

  // console.log('init payload' + JSON.stringify(initialPayload));

  let payload = data.detail;
  localStorage.setItem('inappmessage', JSON.stringify(payload));

  // FCM.clearAllNotifications();
}

async function inAppDisplayMessage() {
  if (!window.state.inappMessages) return;

  processing = true;

  let message = localStorage.getItem('inappmessage');

  if (message) {
    message = JSON.parse(message);

    let payload = message;

    let title;

    //if this looks like a json object then we need to parse it
    if (typeof payload.title === 'string' && payload.title && payload.title.startsWith('{')) {
      try {
        payload.title = JSON.parse(payload.title);
      } catch (e) {
        console.log('Error parsing title');
      }
    }

    if (typeof payload.title === 'object') {
      title = payload.title['any'];

      if (payload.title[localStorage.getItem('language')]) {
        title = payload.title[localStorage.getItem('language')];
      }
    } else {
      title = payload.title;
    }

    let body;

    if (typeof payload.title === 'string' && payload.body && payload.body.startsWith('{')) {
      try {
        payload.body = JSON.parse(payload.body);
      } catch (e) {
        console.log('Error parsing body');
      }
    }

    if (typeof payload.body === 'object') {
      body = payload.body['any'];

      if (payload.body[localStorage.getItem('language')]) {
        body = payload.body[localStorage.getItem('language')];
      }
    } else {
      body = payload.body;
    }

    let button;

    if (typeof payload.title === 'string' && payload.button && payload.button.startsWith('{')) {
      try {
        payload.button = JSON.parse(payload.button);
      } catch (e) {
        console.log('Error parsing button');
      }
    }

    if (typeof payload.button === 'object') {
      button = payload.button['any'];

      if (payload.button[localStorage.getItem('language')]) {
        button = payload.button[localStorage.getItem('language')];
      }
    } else {
      button = payload.button;
    }
    const inapptodisplay = {
      ...payload,
      title: title,
      body: body,
      button: button,
    };

    const node = $('<div>');

    message.image_src ? node.append(z2hApp.constructBlock(image(inapptodisplay.image_src))) : null;

    node.append(z2hApp.constructBlock(window.z2hTemplates.compileBlockAttribute(info(inapptodisplay.body))));

    message.button && node.append(buildButton(inapptodisplay.action, inapptodisplay.data_id, inapptodisplay.button));
    DialogMgr.showMsg({ closeEnabled: true, msgNode: node[0], titleText: inapptodisplay.title });

    $('.cta-button').on('click', () => {
      $('.dialogMgr_root').click();
    });
    $('.dialogMgr_root').on('click', () => {

    localStorage.removeItem('inappmessage');
      processing = false;
    });
  }
}

//document.addEventListener('deviceready', () => {
//  listen(2000);
//});
const { setOnAuthStateChanged } = require('../app/firebaseUtils');

let listener = null;
setOnAuthStateChanged((user, userChanged) => {
  if (user && userChanged) {
    listener && clearTimeout(listener);
    listener = listen(2000);
  }
});

const listen = (timeout) => {
  return setTimeout(() => {
    if (!$('.overlay').is(':visible') && !$('.lock-screen').is(':visible') && !window.state.userData.activeOnboarding) {
      !window.state.inappMessages && initialise();
    }
    //Only display if we are on one of the allowed screens.
    if (
      (!processing &&
        window.state.inappMessages &&
        localStorage.getItem('inappmessage') &&
        allowedTemplates.indexOf(window.state.lastTemplateId) >= 0) ||
      !window.state.lastTemplateId
    ) {
      inAppDisplayMessage();
    }
    listen(2000);
  }, timeout);
};
