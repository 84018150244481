const { badges } = require('.');
const md5 = require('md5');
const { MOBILE_VIEW_BREAKPOINT } = require('../../config/app');
const strings = require('../../config/strings');
// =================================================================================================

// =================================================================================================
const shouldDisplayFacebook = (bannerData, bannerContext) => {
  return true;
};
const shouldDisplayTwitter = (bannerData, bannerContext) => {
  return true;
};
const shouldDisplayLinkedin = (bannerData, bannerContext) => {
  return true;
};
const shouldDisplayInstagram = (bannerData, bannerContext) => {
  return true;
};
const shouldDisplayOneFriend = (bannerData, bannerContext) => {
  return window.state.launchData.home.referral_shared_amount < 1;
};
const shouldDisplayFiveFriends = (bannerData, bannerContext) => {
  return (
    window.state.launchData.home.referral_shared_amount > 1 && window.state.launchData.home.referral_shared_amount < 5
  );
};
const shouldDisplayMultipleDevices = (bannerData, bannerContext) => {
  return !window.state.launchData.home.step_multiple_devices;
};

const weightings = [
  { weight: 1, time: 30000 },
  { weight: 2, time: 60000 },
  { weight: 5, time: 320000 },
  { weight: 10, time: 640000 },
];
const priorityWeighting = [
  { priority: 1, weight: 10 },
  { priority: 2, weight: 5 },
  { priority: 3, weight: 3 },
  { priority: 4, weight: 2 },
  { priority: 5, weight: 1 },
];
const defaultBannerData = {
  id: 'banner_id',
  image_src: null,
  action: null,
  data_id: null,
  priority: 1,
  context: [
    { location: 'menu', image: [] },
    { location: 'profile', image: [] },
    { location: 'landingPage', image: [] },
  ],
  insertable: true,
};
const bannerData = [];

const bannerDataStatic = () => {
  let t = localStorage.getItem('language') ? localStorage.getItem('language') : 'gb';
  t = t === 'uk' ? 'gb' : t;

  return [
    //Twitter

    {
      id: 'twitter',
      image_src: `img/banners/${t}/twitter.jpg`,
      action: 'openBannerUrlSocial',
      data_id: 'https://twitter.com/forghettiapp',
      priority: 5,
      context: [
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayTwitter,
    },

    //Facebook
    {
      id: 'facebook',
      image_src: `img/banners/${t}/facebook.jpg`,
      action: 'openBannerUrlSocial',
      data_id: 'https://www.facebook.com/forghetti',
      priority: 5,
      context: [
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayFacebook,
    },

    //Linked in
    {
      id: 'linkedin',
      image_src: `img/banners/${t}/linkedin.jpg`,
      action: 'openBannerUrlSocial',
      data_id: 'https://www.linkedin.com/company/forghetti/',
      priority: 5,
      context: [
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayLinkedin,
    },

    //Insta
    {
      id: 'instagram',
      image_src: `img/banners/${t}/instagram.jpg`,
      action: 'openBannerUrlSocial',
      data_id: 'https://www.instagram.com/forghetti.app/',
      priority: 5,
      context: [
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayInstagram,
    },

    //One friend
    {
      id: 'onefriend',
      image_src: `img/banners/${t}/friend-share.jpg`,
      action: 'inviteAFriend',
      priority: 4,
      context: [
        { location: 'menu', image: ['mobile', 'desktop'] },
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayOneFriend,
    },
    //Five friend
    {
      id: 'fivefriends',
      image_src: `img/banners/${t}/friends-share.jpg`,

      action: 'inviteAFriend',
      priority: 4,
      context: [
        { location: 'menu', image: ['mobile', 'desktop'] },
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayFiveFriends,
    },
    {
      id: 'multipledevices',
      image_src: `img/banners/${t}/multiple-devices.jpg`,
      action: 'openBannerUrl',
      data_id: 'https://www.forghetti.com/eng/download/a',
      priority: 5,
      context: [
        { location: 'menu', image: ['mobile', 'desktop'] },
        { location: 'profile', image: ['mobile', 'desktop'] },
        { location: 'landingPage', image: ['desktop'] },
      ],
      insertable: shouldDisplayMultipleDevices,
    },
  ];
};

// const buildBannerImage = (banner, display) => ({
//   template: 'block-banner',
//   selector: '',
//   attributes: [
//     { selector: '', type: 'context', content: display },

//     { selector: '.image-wrapper--inner', type: 'data-actionclick', content: banner.action },
//     { selector: '.image-wrapper--inner', type: 'data-id', content: banner.data_id },
//     { selector: 'img', type: 'src', content: banner.image_src },
//   ],
//});
const buildBannerImage = (banner, display) => ({
  template: 'block-banner',
  fields: {
    context: display,
    action: banner.action,
    id: banner.id,
    image: banner.image_src,
    html: banner.html_src,
    data_actiondetail: banner.data_id,
  },
});

const buildDownloadTag = () => ({
  template: 'block-section-row',
  fields: {
    secondary_heading_html: navigator.platform.includes('Mac')
      ? strings.FORGHETTI_ON_MAC()
      : strings.FORGHETTI_ON_WINDOWS(),
    class: 'centered-text',
  },
});

function getDynamicData() {
  // window.state.updateSectionsPromise = z2hApp.requestData('get', '', 'updates/sections').then(response => {
  //   if (Math.floor(response.status / 100) === 2) {
  //     const sections = response.data;
  //     window.state.updateSections = Object.assign([], window.state.updateSections, sections);
  //   }
  //   return window.state.updateSections;
  // });
  //z2hApp.reloadUpdateSections();
}

function refresh(event, { newPane }) {
  if (!window.state.launchData) return;
  //See if the new pane's got a banner on it
  const bannerPane = newPane.find('.banner');
  //Or if we don't have any update sections to show.
  if (!window.state.updateSections || bannerPane.length) {
    getDynamicData();
  }
  window.state.updateSectionsPromise.then((sections) => {
    //merge in updateSections
    bannerData.length = 0;
    if (sections && sections.sections) {
      for (const s of sections.sections) {
        bannerData.push(Object.assign({}, defaultBannerData, s));
      }
      const journey = {
        id: 'your_journey',
        html_src: sections.your_journey_html,
        action: null,
        data_id: null,
        priority: 1,
        context: [{ location: 'profile', image: [] }],
        insertable: true,
      };
      bannerData.push(journey);
    }
    //Add our static banner data... Unless, we already have something
    //from our server side - match by ID!
    for (const b of bannerDataStatic()) {
      if (!bannerData.find((bd) => bd.id === b.id)) {
        bannerData.push(b);
      }
    }

    //
    displayBanners(newPane);
    bannerPane.removeClass('loading-safe');
  });
}

function displayBanners(pane) {
  $('.banner').each((i, elem) => {
    let validBanners = [];
    const location = $(elem).attr('banner-context');
    for (const b of bannerData) {
      let insertable = true;

      //check if the banner is valid for this location...
      const validForLocation = b.context.length
        ? b.context.find((l) => l && l.location === location)
        : Object.values(b.context).find((l) => l && l === 'object' && l.location === location);

      if (!validForLocation) insertable = false;

      //check if the banner is insertable
      if (insertable && typeof b.insertable === 'function') {
        insertable = b.insertable(b, elem);
      } else if (insertable && typeof b.insertable === 'boolean') {
        insertable = b.insertable;
      }

      if (insertable) {
        validBanners.push(b);
      }
    }

    //we've got a list of valid banners... let's decide which one wins.
    if (validBanners.length) {
      //crude weigthin randomness
      validBanners = validBanners.map((banner) => {
        //when was this banner last displayed?
        let now = Date.now();
        const lastTime = localStorage.getItem(`banner_${location}_${banner.id}`) || 0;
        let priorityWeight = 1;

        //the longer we've waited to show this banner, the higher the weighting.
        for (const w of weightings) {
          if (now - lastTime >= w.time) {
            priorityWeight = priorityWeight * w.weight;
          }
        }
        //The higher the priority the higher the weighting.
        banner.weight = priorityWeighting.find((weight) => weight.priority === banner.priority).weight * priorityWeight;
        if (banner.priority === 1) {
          banner.weight = 999999;
          if (banner.id === 'your_journey') {
            banner.weight = 9999999;
          }
        }
        return banner;
      });

      //we're going to see which one with the same priority get displayed
      validBanners.sort((a, b) => b.weight - a.weight);

      if (!window.state.banner) {
        window.state.banner = {};
      }
      let template;
      if (location === 'landingPage') {
        template = [];
        for (let i = 0; i < 2; i++) {
          template.push(buildBannerImage(validBanners[i], location));
        }
        if (!window.cordova && !window.state.electron) {
          template.push(buildDownloadTag());
        }
      } else {
        template = buildBannerImage(validBanners[0], location);
      }

      const existingTemplate = window.state.banner[location];
      const stringy = JSON.stringify(template);

      if (existingTemplate === stringy) return;
      window.state.banner[location] = stringy;

      //Don't redisplay if we don't have to...
      // let hashData = validBanners.map(vB => {
      //   const source = vB.image_src ? vB.image_src : vB.html_src;
      //   return { id: vB.id, source };
      // });
      // hashData = location === 'landingPage' ? hashData.slice(0, 2) : hashData.slice(0, 1);

      // hashData = JSON.stringify({ ...hashData });
      // if (hashData && hashData !== window.state.banner[location]) {
      //we can take the top two for landing page
      const $elem = $(elem).parent(); //.empty();
      const z2hTemplates = require('../app/templates');
      //window.state.banner[location] = hashData;

      if (location === 'landingPage') {
        const $elemToInsert = $(document.createDocumentFragment());

        for (let i = 0; i < 2; i++) {
          localStorage.setItem(`banner_${location}_${validBanners[i].id}`, Date.now());
          //validBanners[i].image_src
          $elemToInsert.append(
            z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(buildBannerImage(validBanners[i], location))),
          );

          //: $elem.append(validBanners[i].html_src);
        }
        if (!window.cordova && !window.state.electron) {
          $elemToInsert.append(z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(buildDownloadTag())));
        }
        $elem.empty().append($elemToInsert);

        $('#landing-page').removeClass('loading-landing-page');
      } else {
        localStorage.setItem(`banner_${location}_${validBanners[0].id}`, Date.now());
        //validBanners[i].image_src
        $elem.html(
          z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(buildBannerImage(validBanners[0], location))),
        );
        //  : $elem.append(validBanners[i].html_src);
      }
    }
    // }
  });
}

// Whenever a new page is displayed, check if it contains any components we need to initialise
$('body').on('z2hApp.pagedisplayed', refresh);
