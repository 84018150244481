/* eslint-disable no-use-before-define */
const $ = require('jquery');

const fb = require('./firebaseUtils');
const { FINGERPRINT_TITLE, FINGERPRINT_DESCRIPTION, FINGERPRINT_DESCRIPTION_FACE } = require('../../config').strings;

// eslint-disable-next-line prefer-const
let { CORDOVA } = process.env;

let authType;
let checked = false;
let checking = false;
function checkDisplayLockScreen() {
  const { Fingerprit } = window;
  // Checking if lock screen should be displayed. We should only need to do this once, as it's
  // only displayed on startup, and if the user is logged in and they have the feature enabled.
  //if (checked) return;
  // On iOS, we often get AuthStateChanged immediately with no current user, soon followed by
  // AuthStateChanged once it had discovered that the user is actually logged in.
  // So don't assume we are done here until 5 secs have passed without getting called again.
  //setTimeout(_ => {
  //  checked = true;
  //}, 4000);

  function isFingerprintAvailable() {
    return new Promise((resolve, reject) => {
      window.Fingerprint.isAvailable(
        (type) => {
          console.log('Fingerprint available', type);
          resolve(type);
        },
        (error) => {
          reject('PROBLEM');
        },
      );
    });
  }

  let enabled = localStorage.getItem('userprefs-touchid') === 'true';
  const promise = Promise.resolve();
  enabled &&
    window.Fingerprint &&
    promise.then(() => {
      isFingerprintAvailable()
        .then((type) => {
          authType = type;

          return displayLockScreen();
        })
        .catch((error) => {
          console.log('Fingerprint unavailable');
          hideLockScreen();
        });
    });
  promise.then((_) => {
    if (!CORDOVA || !enabled || !fb.getCurrentUser() || window.state.faceIdActivated) {
      hideLockScreen();
      return;
    }
  });
}

function displayLockScreen() {
  console.log('display lock screen');
  const { Fingerprint } = window;
  if (!Fingerprint) return;

  //$('.main').hide();
  //$('.overlay').hide();

  $('.lock-screen').show();
  setTimeout((_) => {
    if (!window.state.faceIdActivated) {
      //$('.lock-screen .lock-screen__title').hide();
      Fingerprint.show(
        {
          disableBackup: true,
          title: FINGERPRINT_TITLE(),
          description: authType === 'face' ? FINGERPRINT_DESCRIPTION_FACE() : FINGERPRINT_DESCRIPTION(),
        },
        fingerprintAuthSuccessCallback,
        fingerprintAuthErrorCallback,
      );

      checking = false;
    } else {
      hideLockScreen();
    }
  }, 1000);
}

function hideLockScreen() {
  checking = false;
  $('.lock-screen').fadeOut();
  //$('.main').show();
  //$('.overlay').show();
  $('body').trigger('z2hApp.statusBarChange');
}

function showLockScreen() {
  if (!window.state.faceIdActivated) {
    $('.lock-screen').show();
  }
}
function fingerprintAuthSuccessCallback(result) {
  console.log('Fingerprint/touch ID success: ' + JSON.stringify(result));

  window.state.faceIdActivated = true;
  window.state.userData = window.state.userData || {};
  //window.state.userData.activeOnboarding = false;
  //$('body').trigger('z2hApp.pagedisplayed', { newPane: $('#pane-1-inner').find('.active') });
  hideLockScreen();
}

function fingerprintAuthErrorCallback(error) {
  console.log('Fingerprint/touch ID auth error: ' + JSON.stringify(error));

  $('.lock-screen .lock-screen__title').show();
}

// =================================================================================================
// Event handlers

if (CORDOVA) {
  fb.setOnAuthStateChanged((user) => {
    console.log('Auth state changed', user);
    if (!checking) {
      checking = true;
      checkDisplayLockScreen();
    }
  });
} else {
  hideLockScreen();
}

// If the user clicks the lock screen, redisplay the native fingerprint lock again
$('.lock-screen').click((_) => displayLockScreen());
