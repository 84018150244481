const $ = require('jquery');

//import * as Sentry from '@sentry/browser';

//import { Integrations } from '@sentry/tracing';

const showToastMessage = require('../action_helpers/showToastMessage');

const config = require('../../config');
const { LOGROCKET_ID, SENTRY_ID, SENTRY_ENV, MOBILE_VIEW_BREAKPOINT, CORDOVA } = config.app;

window.$ = window.jQuery = $; // Define jQuery globally for plugins

const loadingBar = require('./loadingBar');
const fb = require('./firebaseUtils');
const SAB = require('./smart-app-banner');
require('./doodlepad');
require('tooltipster');
require('intl-tel-input');
require('../../node_modules/intl-tel-input/build/js/utils'); // intl-tel-input/utils
require('country-select-js');
require('../../node_modules/country-select-js/build/css/countrySelect.css');
require('../components');
require('./context-menu');
require('./lock-screen');
const packageJson = require('../../package.json');
const z2hTemplates = require('./templates');
const z2hApp = require('./z2hApp');
const getQueryStringParameters = require('../utils/getQueryStringParameters');
const removeQueryStringParameter = require('../utils/removeQueryStringParameter');

window.waitingForFirstAuthStateChange = true;

window.waitingForFirstAuthStateChangeTries = 0;

// =================================================================================================
// Execute the following when the page has loaded
$(async (_) => {
  // Prevent this code executing multiple times after hot-reload (during development)
  if (window.appinitialised) return;
  window.appinitialised = true;

  console.info('Initialising forghetti... v' + packageJson.version);

  // Check if we are being loaded in Google Chrome extension (forghetti-chrome-extension)
  window.state = window.state || {};

  config.strings.setLocale(localStorage.getItem('language')) || 'gb';

  const queryString = window.location.search;
  window.state.extension = queryString.includes('?ext=1') || queryString.includes('&ext=1');
  if (queryString.includes('env=electron')) {
    const qsParms = getQueryStringParameters();
    const os = (qsParms.find((q) => q.key === 'os' || q.key === 'os') || {}).value;
    window.state.electron = os;
  }
  /* Replaces any strings like {{CONTEXT_UPLOAD_GROUP_IMAGE}} with strings.CONTEXT_UPLOAD_GROUP_IMAGE */
  const strings = require('../../config/strings');

  const translate = '[translate]';
  $(translate).each((i, elem) => {
    let html = $(elem).html();
    let strTemplate = html.match(/{{[A-Z0-9\_]+}}/);
    while (strTemplate) {
      const strName = strTemplate[0].replace('{{', '').replace('}}', '');
      html = html.replace(strTemplate[0], strings[strName]() || '');

      strTemplate = html.match(/{{[A-Z0-9\_]+}}/);
    }
    $(elem).html(html);
  });

  const showImport = '[isElectronShow]';
  $(showImport).each((i, elem) => {
    if (!window.state.electron) {
      $(elem).hide();
    }
  });

  //console.log('CONFIG', SENTRY_ID, SENTRY_ENV);
  // Sentry.init({
  //   dsn: SENTRY_ID,
  //   environment: SENTRY_ENV,
  //   integrations: [new Integrations.BrowserTracing()],

  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });

  //initializeApp(config.app.FIREBASE);
  console.log('CORDOVA: ', process.env.CORDOVA);
  console.log('isElectron', window.state.electron);
  // if (!window.state.electron && 'serviceWorker' in navigator) {
  //   navigator.serviceWorker.register(`../../sw.js?v=${packageJson.version.replace('.', '')}`);
  // }

  // Firebase events
  // ===============
  //fb.onAuthStateChanged(() => firebase.analytics());
  fb.setOnAuthStateChanged((user, userChanged) => {
    if (user && userChanged) {
      checkForAccessRequest();
    }
  });

  fb.setOnAuthStateChanged(setOnAuthStateChanged);
  fb.setOnAuthStateChanged(async (user, userChanged) => {
    if (user && userChanged) {
       require('./dynamicLinks');
       require('./realtime');
       require('./notes');
 
    }
  });

  if (process.env.CORDOVA) {
    fb.setOnAuthStateChanged(() => {
      window.ss = new window.cordova.plugins.SecureStorage(
        () => {
          console.log('Initialised storage');
        },
        () => {
          console.log('Failed storage');
        },
        'forghetti',
      );
    });

    fb.setOnAuthStateChanged( (user) => {

      if (user) {

        fb.getDeviceToken().then((token) => {
          if (token) {
            z2hApp.requestData('post', '', 'users/@me/update-device-token', { device_id: token , mobile_version : packageJson.mobile_version });
          }
        });

        fb.onDeviceTokenRefresh((token) => {
          if (token) {
            z2hApp.requestData('post', '', 'users/@me/update-device-token', { device_id: token,  mobile_version : packageJson.mobile_version });
          }
        }
        );
      }
    });
  }

  fb.setOnAuthStateChanged((user, userChange, fromCallback) => {

    if (fromCallback) {
      window.waitingForFirstAuthStateChange = false;
      window.waitingForFirstAuthStateChangeTries = 0;
      $('#splash-screen .loading-msg-delay').hide();
    }
  });
  // Initialize Firebase
  await fb.initialise();

  // LogRocket - session logging
  // ==============================
 // if (LOGROCKET_ID) logrocket.init(LOGROCKET_ID);

  // Sentry - error logging
  // ==============================
  // if (SENTRY_ID && SENTRY_ENV) {
  //   Sentry.init({ dsn: SENTRY_ID, env: SENTRY_ENV });
  // }

  // if (!window.cordova) {
  //   //FCM messaging for the web
  //   if (isSupported()) {
  //     onMessage(getMessaging(), (payload) => {
  //       //'Message received. ', payload);
  //       localStorage.setItem('inappmessage', JSON.stringify(payload.data));
  //       // ...
  //     });
  //     getToken(getMessaging(), { vapidKey: config.app.FIREBASE_MESSAGING_VAPIDKEY }).then((currentToken) => {
  //       //TODO: send token to server
  //       window.state.inappMessages = true;
  //     });
  //   }
  // }
  // Bind event handlers
  // ===============================================================================================
  // Elements

  const body = $('body');

  body.on('click', '.notification-message .cancel-message', () => {
    $('.notification-message').fadeOut();
    localStorage.removeItem('verifyRequest');
  });

  setInterval(() => {
    if (localStorage.getItem('verifyRequest')) {
      if (!$('.notification-message').is(':visible')) {
        const data = JSON.parse(localStorage.getItem('verifyRequest'));
        const text = $('#message-bar').text();
        const newText = text.replace('{{service}}', data.name);
        $('#message-bar').text(newText);
        $('.notification-message').addClass('show');
      }
    } else {
      if ($('.notification-message').is(':visible')) {
        $('.notification-message').removeClass('show');
      }
    }
  }, 1000);
  body.on('focus', 'input[type="text"],input[type="tel"]', z2hApp.inputFieldFocus);

  body.on('click', '[data-nav], [data-actionclick]', z2hApp.onComponentClick);
  body.on('click', '.radio-button', z2hApp.colourRadioButton);

  // body.on('click', '.activation-input', z2hApp.activationBoxesClick);
  // body.on('keydown', '.activation-input', z2hApp.activationBoxesKeydown);

  body.on('keyup', '#pane-1 .search input', z2hApp.searchInputChange);
  body.on('keyup', '[data-actionkeyup]', z2hApp.actionKeyupChange);
  body.on('change', '[data-actionchange]', z2hApp.actionKeyupChange);

  // Doodlepad buttons
  body.on('click', '.doodlepad-button-secret', z2hApp.toggleSecretDoodlepad);
  body.on('click', '.doodlepad-button-clear', z2hApp.clearCurrentDoodlepad);
  // Doodlepad buttons
  body.on('click', '.doodlepad-button-tryagain', z2hApp.biometricsTryAgain);
  body.on('click', '.doodlepad-button-usedoodle', z2hApp.biometricsUseDoodle);

  // Navigation
  $('#groups-nav').on('click', 'li', z2hApp.showServicesForGroup);
  body.on('click', '.nav-bar__home', () => z2hApp.showServicesForGroup());

  // If any link is clicked in the main (side) nav, display the landingPage in pane2
  $('#main-nav').on('click', 'li', z2hApp.onClickMainNavItem);
  body.on('z2hApp.statusBarChange', z2hApp.setStatusBar);

  //TODO clean up + cconvert to component
  body.on('touchstart click', '.search-input-clear', function (event) {
    //set the value to blank of the input field that preceeded this.
    event.target.previousElementSibling.value = '';
    $(this).prev().trigger('keyup');
  });

  body.on('keyup mouseenter mouseleave ', 'input[type="search"]', function (event) {
    event.target.value !== ''
      ? event.target.nextElementSibling.classList.add('search-input-clear')
      : event.target.nextElementSibling.classList.remove('search-input-clear');
  });

  document.addEventListener('backbutton', z2hApp.backButton);
  // Handler for events coming from Updates page HTML (from an iFrame)
  window.addEventListener('message', z2hApp.updatesInternalHandler, false);
  window.addEventListener('statusTap', function () {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    $('.section-pane').scrollTop(0);
    $('.login-list-wrapper').scrollTop(0);
  });

  //Device ready listener only applies to the cordova app
  document.addEventListener('deviceready', () => {
    lockOrientationAndHideLandingPage();
  });

  document.addEventListener('pause', () => {
    //log the time the app was paused
    window.state.pauseTime = new Date().getTime();
  });
  document.addEventListener('resume', () => {
    //log the time the app was paused
    const pauseTime = window.state.pauseTime;
    const resumeTime = new Date().getTime();
    const timeDiff = resumeTime - pauseTime;
    if (timeDiff > 5000) {
      const $pageActive = $('#view-service')
      if ($pageActive.length > 0) {
        z2hApp.pageData = {};
        z2hApp.pageData.service = {};
        $pageActive.find('[data-template=back]').click();
      }
    }
  });
  //Orientation change listener only applies if the device is recognised as being mobile not desktop
  window.addEventListener('orientationchange', function () {
    lockOrientationAndHideLandingPage();
  });

  function lockOrientationAndHideLandingPage() {
    //Check to see if the screen width/height is smaller than our mobile breakpoint
    //If it is, fix it in the portrait-primary orientation because the app looks best in mobile view on small screens
    if (
      //Screen orientation not already locked by event listeners
      (!window.state.lockOrientation &&
        //Screen is portrait
        (((screen.orientation.type === 'portrait' || screen.orientation.type === 'portrait-primary') &&
          //Screen width is smaller than or equal to our mobile breakpoint
          //screen height is smaller than our mobile breakpoint
          window.screen.width < MOBILE_VIEW_BREAKPOINT) ||
          window.screen.height < MOBILE_VIEW_BREAKPOINT)) ||
      //Screen is landscape
      ((screen.orientation.type === 'landscape' || screen.orientation.type === 'landscape-primary') &&
        //Screen height is smaller than or equal to our mobile breakpoint
        //screen width is smaller than our mobile breakpoint
        window.screen.width < MOBILE_VIEW_BREAKPOINT) ||
      window.screen.height < MOBILE_VIEW_BREAKPOINT
    ) {
      screen.orientation.lock('portrait-primary');
      window.state.lockOrientation = true;
    } else if (!window.state.lockOrientation) {
      //Screen orientation has changed, but it is large enough to show the app in landscape
      //Make sure the screen orientation is not locked
      screen.orientation.unlock();
    }
  }

  // ===============================================================================================

  // Take this opportunity to replace some strings in overlay/splashscreen with configured strings
  $('#splash-screen .loading-msg').text(config.strings.COMBOBULATING());
  $('#splash-screen .loading-msg-delay').text(config.strings.WEB_DELAY());
  $('.overlay #tag1').text(config.strings.FORGHETTI_LOGIN_TAGLINE());

  $('.overlay #tag2').text(config.strings.FORGHETTI_LOGIN_TAGLINE_2());
  $('.overlay .cta-button[data-template=verifyDevice1]').text(config.strings.CREATE_ACCOUNT());
  $('.overlay .text-button[data-template=verifyDevice1]').text(config.strings.HAVE_ACCOUNT());

  //TODO put this somewhere proper!!
  if (!localStorage.getItem('language')) {
    localStorage.setItem('language', 'gb');
  }

  let language = config.app.LANGUAGES.find((l) => l.iso2 === localStorage.getItem('language'));
  if (!language) {
    language = config.app.LANGUAGES[0];
  }
  $('.overlay [language]').find('[currentLanguage]').text(language.name);
  const languagesContext = $('[data-menu="languages"]');
  const $header = $('<div/>').addClass('context-menu__heading').text(config.strings.FORGHETTI_SELECT_LANGUAGE_INFO());

  const $ul = $('<ul>').addClass('context-menu__items');
  for (const l of config.app.LANGUAGES) {
    const $li = $('<li>').addClass('context-menu__item');
    const $a = $('<a/>')
      .addClass('context-menu__link')
      .attr('onclick', 'window.contextMenuHandler(event)')
      .attr('data-task', 'selectLanguage')
      .attr('data-taskDetails', l.iso2)
      .text(l.name);
    $li.append($a);
    $ul.append($li);
  }
  languagesContext.append($header);
  languagesContext.append($ul);

  // Update year in copyright message
  $('.current-year').text(new Date().getFullYear());

  // Go through all user preferences and add classes to the <body> to indicate which
  // user preferences have been enabled, for associated styling purposes
  ['tooltips', 'quickselect'].forEach((pref) => {
    const enabled = localStorage.getItem('userprefs-' + pref) === 'false' ? false : true;
    $('body').toggleClass('userprefs-' + pref, enabled);
  });

  // Ensure that the preference for secret doodlepad is false by default. Ensuring it is set to
  // false rather than null just makes our checking easier in other parts of the app. All of the
  // other preferences we assume are true by default, so this one is an exception.
  const secretDoodleByDefault = localStorage.getItem('userprefs-secretdoodle');
  if (secretDoodleByDefault === null) {
    localStorage.setItem('userprefs-secretdoodle', false);
  }

  window.state = window.state || {};

  try {
    const topPresets = JSON.parse(localStorage.getItem('top-presets')) || [];
    window.state.topPresets = topPresets;
  } catch (err) {
    // Could not load top presets from cache. No big deal
  }

  loadingBar.increment(1);
  if (CORDOVA) {
    document.addEventListener('deviceready', (_) => loadingBar.increment(1), false);
  } else {
    loadingBar.increment(1);
  }
});

// =================================================================================================
let loadingBarTimeout;
async function setOnAuthStateChanged(user, userChanged, fromCallback) {
  console.info('Auth state changed. User: ', fb.getCurrentUser()?.uid);

  // User is not logged in

  loadingBar.increment(2);
  if (!fb.getCurrentUser()) {
    // Complete the loading bar
    // We wait 1sec before doing this, as in Cordova there is no user initially, but then cordova
    // gets its act together and discovers the user is already logged in. So we should not be
    // too hasty in displaying the "Create account" screen.

    if (fromCallback || (user && fromCallback)) {
      loadingBarTimeout = setTimeout((_) => loadingBar.increment(4), 1000);
    }
    if (!CORDOVA) {
      //fb.analyticsLogEvent('User not logged in');
    }

    return;
  }
  clearTimeout(loadingBarTimeout);
  if (!CORDOVA) {
    fb.analyticsLogEvent('User logged in');
  }

  //window.waitingForFirstAuthStateChange = false

  //z2hApp.checkPartnerSubscription();
  // if (window.state.extension) Sentry.captureMessage('Logged in to extension');
  // else Sentry.captureMessage('Logged in');

  if (user && userChanged) {
    z2hApp.getInitialLaunchData().then(() => {
      displayForghettiMain();
      z2hApp.launchDataPostActions();
    });
  }
}

// =================================================================================================

// eslint-disable-next-line consistent-return
function displayForghettiMain() {
  loadingBar.increment(2);
  loadingBar.increment(2);

  // ===================================================================================
  // For testing, show onboarding process for all users

  if (!window.state.userData) {
    window.state.userData = {};
  }
  window.state.userData.activeOnboarding = true;
  //window.state.userData.activeOnboarding = true;
  if (localStorage.getItem('onboarding_complete')) {
    window.state.userData.activeOnboarding = false;
  }
  if (window.state.launchData && window.state.launchData.onboarding) {
    window.state.userData.activeOnboarding = false;
  }
  // z2hApp.reloadFullGroupsList();
  // setInterval(() => {
  //   if (window.state.launchData.uid) {
  //     z2hApp.reloadFullGroupsList(true);
  //   }
  // }, 120000);

  checkForAddLogin();

  $('body').trigger('z2hApp.statusBarChange');

  $('#recaptchaContainer').hide();

  // Load landing page into pane-2
  // =============================
  const desktopView = $('body').width() > MOBILE_VIEW_BREAKPOINT;
  if (desktopView) {
    const getData = z2hTemplates.getPageData('notifications');
    getData.then((data) => {
    const template = z2hTemplates.getPage('notifications', 0, data);
    const page = z2hApp.createPageFromTemplate(template);

    $('#pane-2-inner').append(page);
   
    $('body').trigger('z2hApp.pagedisplayed', { newPane: $('#pane-2-inner').children('.section-pane').eq(0) });
    z2hApp.setSectionPaneActive(1);
    });
  }
  z2hApp.initUpdatesPage('main-nav-banner', 'main-nav-banner');

  // -----------------------------------------------------------------------------------------------

  checkForReonboarding();

  // If there is an initial search query in the URL, then apply that search to the services lists
  checkForInitialSearchQuery();
  // If there is a referral code in the URL then redirect to the profile -> referral code page
  checkForReferralCode();
  // If there is a group invite code in the URL then join the group and display a message
  checkForGroupInviteCode();
  // Display the app banner to encourage users to download the Forghetti mobile app
  //displaySmartAppBanner();
  checkForExtenionUrl();

  checkForPartnerLicenseKeyLogin();

  if (window.state.userData.activeOnboarding) return displayNewAccountProcess();
  $('.overlay').hide();
}

// =================================================================================================

function displayNewAccountProcess() {
  // If we appear to be in the middle of the Create Account process,
  // then take the user to the referral code entry screen. But
  // otherwise go to the page to enter a new display name.
  //  const pageToShow = $('#verify-device-3').is(':visible') ? 'createAccount_referralCode' : 'onboarding_stage1';
  $('body').trigger('z2hApp.statusBarChange');
  if (window.state.processingOnboarding) return;
  window.state.processingOnboarding = true;

  const existingUser = window?.state?.launchData?.personal_group_services_list?.length >= 1;
  const onboardingTemplate = existingUser ? 'onboarding_existingUser' : 'onboarding_stage1';

  const pageToShow = $('#verify-device-3').is(':visible') ? 'createAccount_referralCode' : onboardingTemplate;

  if (existingUser) localStorage.setItem('reonboard_prompt', true);
  if (document.activeElement) {
    document.activeElement.blur();
  }
  z2hApp.paneNavigation(
    pageToShow,
    $('.overlay'),
    $('.overlay').find('section.active').index() + 1,
    //0,
    null,
    null,
    true,
    () => {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      $('.overlay').find('.section-pane-wrapper').children().not('.active').remove();
    },
  );
  // Set focus on the first text input field in the new page
  setTimeout((_) => $('.overlay .text-input').focus(), 1000);
}

// =================================================================================================

// Banner to be displayed when using the web app on mobile devices to encourage them to download
// the app from the app store
function displaySmartAppBanner() {
  if (window.state.extension || window.cordova) return;

  setTimeout((_) => {
    // eslint-disable-next-line no-new
    new SAB({
      daysHidden: 0, // days to hide banner after close button is clicked (defaults to 15)
      daysReminder: 0, // days to hide banner after "VIEW" button is clicked (defaults to 90)
      appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
      title: 'forghetti - Password Manager',
      author: 'Forghetti Ltd',
      button: 'View',
      store: {
        android: 'On the Google Play Store',
        // , ios: 'On the App Store'
        // , windows: 'In Windows store'
      },
      price: {
        android: 'FREE',
        // , ios: 'GET'
      },
      // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
      icon: './forghetti-app-icon-192px.png', // full path to icon image if not using website icon image
      // , force: 'ios' // Uncomment for platform emulation
    });
  }, 1200);
}

// =================================================================================================

// If there is a group invite code in the URL then join the group and display a message
function checkForGroupInviteCode() {
  const qsParms = getQueryStringParameters();
  const inviteCodeParamName = 'invite';
  const inviteCode = (qsParms.find((q) => q.key === inviteCodeParamName) || {}).value;
  if (inviteCode) {
    removeQueryStringParameter('invite');
    localStorage.setItem('pendingEvent', 'invite');
    localStorage.setItem('pendingEventData', inviteCode);
  }
}

// =================================================================================================

// If there is a referral code in the URL then redirect to the edit referral code page
// within the profile section
function checkForReferralCode() {
  const qsParms = getQueryStringParameters();

  const referralCode = (qsParms.find((q) => q.key === 'refer' || q.key === 'referral') || {}).value;
  if (referralCode) {
    removeQueryStringParameter('refer');
    removeQueryStringParameter('referral');

    localStorage.setItem('pendingEvent', 'referral');
    localStorage.setItem('pendingEventData', referralCode);
  }
}

// =================================================================================================

// Check for an initial service search query in the forghetti URL query parameters
function checkForAddLogin() {
  const qsParms = getQueryStringParameters();
  const addLoginWebsite = 'websiteUrl';
  const addLogin = window.location.pathname === '/addLogin';

  const websiteUrl = (qsParms.find((q) => q.key === addLoginWebsite) || {}).value;
  if (addLogin) {
    removeQueryStringParameter(addLoginWebsite);
    localStorage.setItem('pendingEvent', 'login');
    localStorage.setItem('pendingEventData', websiteUrl);
  }
}

function checkForPartnerLicenseKeyLogin() {
  //Cordova links are supported by dynamic links.
  if (!window.cordova) {
    const qsParms = getQueryStringParameters();

    const licenceURL = (qsParms.find((q) => q.key === 'l') || {}).value;

    const processLink = (url) => {
      if (url) {
        fb.analyticsLogEvent('Licence from QS', { link: url });

        let dlurl = url;
        z2hApp

          .applyPartnerLicenceKey({ deepLink: dlurl })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem('pendingEvent', 'partnerPremium');
              localStorage.setItem('pendingEventData', res.data.partner);
            } else {
              if (res.data.error == '1711') {
                fb.analyticsLogEvent('Licence from QS - already used', { link: url });
                showToastMessage(config.strings.LICENCE_KEY_USED()());
              } else if (res.error_code == '1713') {
                fb.analyticsLogEvent('Licence from QS - already applied', { link: url });
                showToastMessage(config.strings.LICENCE_KEY_ALREADY_APPLIED()());
              } else {
                fb.analyticsLogEvent('Licence from QS - invalid', { link: url });
                showToastMessage(config.strings.LICENCE_KEY_INVALID()());
              }
            }
          })
          .catch((e) => {
            showToastMessage(config.strings.LICENCE_KEY_INVALID()());
          });
      }
    };

    if (licenceURL) {
      if (licenceURL.indexOf('_csl_') >= 0 || licenceURL.indexOf('_1010_') >= 0) {
        let dlurl = licenceURL.replace('_csl_', '/licence/csl/');
        dlurl = dlurl.replace('_1010_', '/licence/1010/');
        if (window.state?.launchData?.uid) {
          processLink(dlurl);
        } else {
          localStorage.setItem('pp', '1');
          localStorage.setItem('pp2', JSON.stringify(dlurl));
        }
      }

      removeQueryStringParameter('l');
    }
  }
}

function checkForInitialSearchQuery() {
  const qsParms = getQueryStringParameters();
  const searchParamName = 'search';
  const searchQuery = (qsParms.find((q) => q.key === searchParamName) || {}).value;
  if (searchQuery) {
    removeQueryStringParameter(searchParamName);
    localStorage.setItem('pendingEventSearch', searchQuery);
  }
}

function checkForExtenionUrl() {
  let website =
    window.location.search
      .substr(1)
      .split('&')
      .map((s) => ({ key: s.split('=')[0], value: s.split('=')[1] }))
      .find((p) => p.key === 'website') || {};
  website = decodeURIComponent(website.value || '');

  if (website) {
    window.state.currentTabUrl = website;
    window.state.extension = true;
    localStorage.setItem('pendingEvent', 'extension');
    localStorage.setItem('pendingEventData', website);
  }
}

function checkForReonboarding() {
  const existingUser = window?.state?.launchData?.personal_group_services_list?.length >= 1;

  if (existingUser && localStorage.getItem('reonboard_prompt')) {
    localStorage.setItem('pendingEvent', 'reonboarding');
  }
  localStorage.removeItem('reonboard_prompt');
}

async function checkForAccessRequest() {
  const qsParms = getQueryStringParameters();
  const searchParamName = 'verify';
  const verify = (qsParms.find((q) => q.key === searchParamName) || {}).value;

  const searchId = 'id';
  const search = (qsParms.find((q) => q.key === searchId) || {}).value;

  if (verify) {
    localStorage.setItem('waitingForData', '1');
    const socket = await z2hApp.requestData('get', '', `partner-access/${verify}/service`);
    removeQueryStringParameter(searchParamName);

    console.log('socket', socket);
    localStorage.removeItem('waitingForData');
    localStorage.setItem('pendingEventSearch', socket.data.name);
    localStorage.setItem('pendingEvent', 'verifyRequest');
    localStorage.setItem('pendingEventData', JSON.stringify(socket.data));
  }
}

if (module.hot) {
  module.hot.dispose(function (data) {
    // module is about to be replaced.
    // You can save data that should be accessible to the new asset in `data`
    data.updated = Date.now();
  });

  module.hot.accept(function (getParents) {
    // module or one of its dependencies was just updated.
    // data stored in `dispose` is available in `module.hot.data`
    let { updated } = module.hot.data;
    window.waitingForFirstAuthStateChange = false;
  });
}
