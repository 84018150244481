const USERNAMES_TO_DISPLAY = 8;
const { thirdheading } = require('../page_helpers/components');

const re =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
function updateBlock(usernames, block) {
  // Empty block of previous results

  block.children('.row').remove();
  const action = block.data('action') || block.attr('data-username-action') || 'addService_selectUsername';

  const template = block.attr('data-username-template');
  const data = block.attr('data-username-data');

  // Render each username
  const { z2hTemplates, z2hApp } = window;

  const blockHeading = block.attr('data-heading');
  let headingRow = null;
  if (usernames.length > 0 && blockHeading) {
    headingRow = thirdheading({ text: blockHeading });
  }
  if (headingRow) block.append(z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(headingRow)));

  usernames.forEach((u) => {
    const rowTemplate = {
      template: 'block-section-row',
      fields: {
        navigation_action: action,
      },
      columns: [
        {
          template: 'block-text-group',
          fields: { primary_text: u.username },
        },
        {
          template: 'block-icn',
          fields: {
            icon: 'right',
          },
        },
      ],
    };
    if (template) rowTemplate.fields['navigation_template'] = template;
    if (data) rowTemplate.fields['navigation_data'] = data;

    // Build row
    const row = z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(rowTemplate));

    // Build columns in row
    rowTemplate.columns.forEach((col) => {
      row
        .children('.column')
        .children('.column-group')
        .append(z2hApp.constructBlock(z2hTemplates.compileBlockAttribute(col)));
    });

    block.append(row);
  });
}

function filterUsernameList(value, _rules) {
  let usernames = [...window.state.userData.userNamesList];

  let rules = document?.activeElement?.id ? $(`#${document.activeElement.id}`).attr('data-rules') : null;
  if (!rules) rules = _rules;

  usernames = usernames.filter((user) => {
    if (!user) return false;

    if (rules === 'email') {
      return user.username.toLowerCase().includes(value.toLowerCase()) && re.test(user.username);
    }
    if (rules === 'notemail') {
      return user.username.toLowerCase().includes(value.toLowerCase()) && !re.test(user.username);
    }
    return user.username.toLowerCase().includes(value.toLowerCase());
  });
  return usernames;
}

function filterAndUpdateblock(event, value) {
  const filteredUsername = filterUsernameList(value);
  const usernamesSubset = filteredUsername.slice(0, USERNAMES_TO_DISPLAY);
  updateBlock(usernamesSubset, $(`[data-id="${event.data.id}"]`));
}
// =================================================================================================
function initialise(event, { newPane }) {
  const blocks = newPane.find('.suggested-usernames');

  blocks.each((i, elem) => {
    const block = $(elem);

    const rules = block.attr('data-rules');
    //register an event for the block keyup
    block.on(
      'keyup.suggestedUsernames',
      {
        id: block.data('id'),
        rules: rules,
      },
      filterAndUpdateblock,
    );
    // Action to be taken when a username is clicked
    const action = block.data('action') || block.attr('data-username-action') || 'addService_selectUsername';

    const { selectedGroupId, groupsList } = window.state;

    // Get a list of common usernames
    let usernames = getUsernamesAndCountsInGroup(selectedGroupId);
    let otherUsernamesWithDuplicates = [];
    //if (usernames.length < USERNAMES_TO_DISPLAY) {
    groupsList
      .filter((g) => g.id !== selectedGroupId)
      .forEach((group) => {
        otherUsernamesWithDuplicates.push(...getUsernamesAndCountsInGroup(group.id, usernames));
      });
    //}

    // Sort by frequency
    usernames.sort((a, b) => (a.count > b.count ? -1 : 1));

    const otherUsernames = [];
    for (const username of otherUsernamesWithDuplicates) {
      //Find the duplicate usernames, extract the count, add the counts up
      if (!otherUsernames.find((o) => o.username === username.username)) {
        const count = otherUsernamesWithDuplicates
          .filter((u) => {
            return u.username === username.username;
          })
          .map((a) => a.count)
          .reduce((acc, cur) => acc + cur);
        otherUsernames.push({ count, username: username.username });
      }
    }

    otherUsernames.sort((a, b) => (a.count > b.count ? -1 : 1));
    usernames = usernames.concat(otherUsernames);

    window.state.userData.userNamesList = usernames;
    // Reduce list size

    const filterUsername = filterUsernameList('', rules);
    const usernamesSubset = filterUsername.slice(0, USERNAMES_TO_DISPLAY);

    updateBlock(usernamesSubset, block);
  });
}

// =================================================================================================

function getUsernamesAndCountsInGroup(groupId, selectedGroupUsernames = [], rule) {
  const { groupsList } = window.state;
  const group = groupsList.find((g) => g.id === groupId) || {};

  // Get all usernames from all services in group

  let usernames = [];
  if (group.services) {
    for (const service of group.services) {
      if (service.username) {
        usernames.push(service.username);
      }
      if (service.username_secondary) {
        usernames.push(service.username_secondary);
      }
    }
  }
  usernames.filter(
    (username) =>
      Boolean(username) &&
      selectedGroupUsernames.findIndex((u) => {
        return u.username === username;
      }),
  ); // Exclude nulls/blanks

  const usernamesWithCounts = [];
  usernames.forEach((username) => {
    // Exclude username if it is already in list
    if (!usernamesWithCounts.find((o) => o.username === username)) {
      // Get number of services that have this username
      const count = usernames.filter((u) => u === username).length;

      //If the usernames are in the existing list... add to the count there
      usernamesWithCounts.push({ username, count });
    }
  });

  return usernamesWithCounts; // = [ { username:String, count:Number }]
}

// =================================================================================================
// Event handlers

// Whenever a new page is displayed, check if it contains any components we need to initialise
$('body').on('z2hApp.pagedisplayed', initialise);
