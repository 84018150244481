// =================================================================================================
//function to show or hide the navbar based on block-section-page
function showNavbar(event, { newPane }) {
  const defaults = {
    mobile: true,
    desktop: true,
  };

  //Each new pane can decide whether or not to display the nav-bar, it maybe an object,
  //or a boolean or just not defined. By default we show the nav bar.
  const navbarData = newPane.data('navbar');
  //If we have an object, override the defaults
  const showNavbar = typeof navbarData === 'object' ? { ...defaults, ...navbarData } : { ...defaults };
  //Mobile can be defined more simply as a boolean.
  typeof navbarData === 'boolean' && (showNavbar.mobile = navbarData);

  const view = $('body').width() <= window.z2hApp.MOBILE_VIEW_BREAKPOINT ? 'mobile' : 'desktop';

  if (view === 'mobile') {
    showNavbar[view] === false ? $('.nav-bar').hide() : $('.nav-bar').show();
  } else {
    //Desktop is more complicated. we're only interested in chnges to pane 1
    const pane = newPane.closest('.section-pane-wrapper').parent().attr('id');
    if (pane === 'pane-1') showNavbar[view] === false ? $('.nav-bar').hide() : $('.nav-bar').show();
  }
}

// =================================================================================================
function showPopupMenu(event) {
  event.stopPropagation();

  //We have the scenario where the subscription type hasn't loaded yet...
  //In this case we hide the 'add group' until it's either removed or shown.
  $(`[data-navcontext="nav-bar__popup_group"]`).each((i, elem) => {
    const premium =
      window.state.launchData.home.premium || (window.state.userData.subscription || []).subscription_type !== 'free';
    //!subscriptionType || subscriptionType === "" ? $(elem).hide() : $(elem).show();
    //subscriptionType === "free" && $(elem).remove();
    $(elem)
      .removeAttr('data-template')
      .removeAttr('data-nav')
      .removeAttr('data-nav-pane')
      .removeAttr('data-actionclick');
    if (!premium) {
      $(elem).attr('data-actionclick', 'newGroupGotoPremium');
    } else {
      $(elem).attr('data-template', 'newGroup').attr('data-nav', '1').attr('data-nav-pane', '2');
    }
  });
  const $navbarWrapper = $('.nav-bar__wrapper');
  $navbarWrapper.toggleClass('nav-bar__overlay');
  $navbarWrapper.find('.nav-bar__popup').toggleClass('active');
  $navbarWrapper.find('.nav-bar__button-group').toggleClass('popup');
  $('body').toggleClass('nav-bar--overlay-active');
}

function hidePopupMenu(event) {
  const $navbarWrapper = $('.nav-bar__wrapper');
  $navbarWrapper.removeClass('nav-bar__overlay');
  $navbarWrapper.find('.nav-bar__popup').removeClass('active');
  $navbarWrapper.find('.nav-bar__button-group').removeClass('popup');
  $('body').removeClass('nav-bar--overlay-active');
}

// =================================================================================================
// Event handlers
$('#nav-bar-add').on('click', showPopupMenu);
$('body').on('click', '.nav-bar__popup__item', hidePopupMenu);
$('body').on('click', '.nav-bar__wrapper', hidePopupMenu);

// Whenever a new page is displayed, check if it contains any components we need to initialise
$('body').on('z2hApp.pagedisplayed', showNavbar);
